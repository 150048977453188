<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>阶列表</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-row class="search-card">
                <el-col :span="3">
                    <el-select v-model="searchStatus" placeholder="状态" clearable>
                        <el-option label="创建" value="0"/>
                        <el-option label="入池" value="1"/>
                    </el-select>
                </el-col>
                <el-col :span="4">
                    <!-- 搜索区域 -->
                    <el-input placeholder="搜索" v-model="searchValue" @keyup.enter.native="search">
                        <el-button slot="append" icon="el-icon-search" @click="search"/>
                    </el-input>
                </el-col>
                <el-col :span="2" style="float: right">
                    <el-button type="warning" @click="dialog.visible = true">未完成的阶</el-button>
                </el-col>
            </el-row>
            <el-row>
                <el-table highlight-current-row
                          :data="rankList"
                          size="mini"
                          max-height="550"
                          border>
                    <el-table-column width="150px" prop="itemId" label="ID"/>
                    <el-table-column min-width="150px" prop="matSku" label="编码"/>
                    <el-table-column min-width="250px" prop="matName" label="名称"/>
                    <el-table-column min-width="150px" prop="regionName" label="区间"/>
                    <el-table-column prop="status" label="状态">
                        <template scope="scope">
                            <span v-if="scope.row.status === 0">创建</span>
                            <span v-if="scope.row.status === 1">入池</span>
                            <span v-if="scope.row.status === -1">取消</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="200px">
                        <template slot-scope="scope">
                            <el-button type="text" @click="showRankItem(scope.row)" size="small">查看</el-button>
                            <el-button v-if="scope.row.poolFlag === 1" type="text" @click="poolData(scope.row)" size="small">池数据</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="page.currentPage"
                        :page-sizes="[10,20, 50, 100]"
                        :page-size="page.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="page.total">
                </el-pagination>
            </el-row>
        </el-card>
        <el-dialog title="未创建阶列表" :visible.sync="dialog.visible">
            <el-input placeholder="搜索" v-model="dialog.searchValue" @keyup.enter.native="getUnPlanSale">
                <el-button slot="append" icon="el-icon-search" @click="searchUnCreateMat"/>
            </el-input>
            <el-table :data="dialog.mats">
                <el-table-column property="matId" label="ID"/>
                <el-table-column property="matSku" label="编码"/>
                <el-table-column property="matName" label="名称"/>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="createRank(scope.row)" type="text" size="medium">
                            去创建
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @current-change="matsChangePage"
                    :current-page="dialog.page.currentPage"
                    layout="prev, pager, next"
                    :total="dialog.page.total">
            </el-pagination>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                page: {
                    pageSize: 10,
                    currentPage: 1,
                    total: 0,
                },
                searchValue: "",
                searchStatus: "",
                rankList: [],
                dialog: {
                    searchValue: '',
                    visible: false,
                    mats: [],
                    page: {
                        currentPage: 1,
                        total: 0
                    }
                }
            }
        },
        created() {
            this.getRankList();
            this.getUnCreateMats();
        },
        methods: {
            search() {
                this.page.currentPage = 1;
                this.getRankList();
            },
            handleSizeChange(val) {
                this.page.pageSize = val;
                this.getRankList();
            },
            handleCurrentChange(val) {
                this.page.currentPage = val;
                this.getRankList();
            },
            async showRankItem(item) {
                await this.$router.push({path: '/rank_item', query: {id: item.matId}});
            },
            async poolData(item) {
                await this.$router.push({path: '/pool_data', query: {id: item.matId}});
            },
            async getRankList() {
                const param = {current: this.page.currentPage, size: this.page.pageSize, search: this.searchValue, poolFlag: this.searchStatus};
                const {data: res} = await this.$http.post("/rank/list", param);
                if (res.success) {
                    this.rankList = res.data.records;
                    this.page.total = res.data.total;
                }
            },
            async createRank(mat) {
                await this.$router.push({path: "/rank_design", query: {mat: mat, type: 'CREATE'}});
            },
            matsChangePage(val) {
                this.dialog.page.currentPage = val;
                this.getUnCreateMats();
            },
            searchUnCreateMat() {
                this.dialog.page.currentPage = 1;
                this.getUnCreateMats();
            },
            async getUnCreateMats() {
                const param = {current: this.dialog.page.currentPage, size: 10, search: this.dialog.searchValue};
                const {data: res} = await this.$http.post("/rank/un_create", param);
                if (res.success) {
                    this.dialog.mats = res.data.records;
                    this.dialog.page.total = res.data.total;
                }
            }
        },
        beforeRouteEnter(to,from,next) {
            // 从详情页返回不刷新
            if (from.path === '/rank_item') {
                to.meta.keepAlive = true;
            }else {
                to.meta.keepAlive = false;
            }
            next();
        }
    }
</script>

<style scoped>

</style>