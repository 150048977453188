<template>
    <div class="login_container">
        <div class="login_box">
            <div class="avatar_box">
                <img src="../assets/logo.png" alt="" />
            </div>
            <!-- 表单区域 -->
            <el-form ref="loginFormRef" :model="loginForm" :rules="loginRules" class="login_form" label-width="0" @submit.native.prevent>
                <el-form-item prop="username">
                    <el-input v-model="loginForm.username" placeholder="用户名" prefix-icon="iconfont icon-ziyuanxhdpi"/>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input v-model="loginForm.password" type="password" placeholder="密码" prefix-icon="iconfont icon-mima"/>
                </el-form-item>
                <el-form-item class="btns">
                    <el-button type="primary" @click="login()" native-type="submit">登录</el-button>
                    <el-button type="info" @click="resetLoginForm()">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {

    data() {
        return {
            loginForm : {
                username : "",
                password : ""
            },
            loginRules: {
                username: [
                    {required : true, message: "请输入用户名", trigger : 'blur'},
                    {min: 3, max: 10, message: '长度3~10', trigger: 'blur'}
                ],
                password: [
                    {required : true, message: "请输入密码", trigger : 'blur'}
                ]
            },
        }
    },
    created() {
        // 开发环境直接填入
        if (process.env.NODE_ENV === 'development') {
            this.loginForm.username = "admin";
            this.loginForm.password = "123123";
        }
    },
    methods: {
        // 重置表单页面
        resetLoginForm() {
            this.$refs.loginFormRef.resetFields();
        },
        login() {
            this.$refs.loginFormRef.validate(async valid => {
                if (!valid) return;
                const {data: res} = await this.$http.post("login", this.loginForm);
                if (res.success) {
                    window.sessionStorage.setItem("user", res.user);
                    window.localStorage.setItem("token", res.user.token);
                    await this.$router.push({path: '/home'});
                }else {
                    this.$message.error("失败！！！");
                }
            })
        }
    }
    
}
</script>

<style scoped>
    .login_container{
        background-color: #2b4b6b;
        height: 100%;
    }
    .login_box {
        width: 450px;
        height: 300px;
        background-color: #fff;
        border-radius: 3px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }
    .avatar_box {
        width: 130px;
        height: 130px;
        border: 1px solid #eee;
        border-radius: 50%;
        box-shadow: 0 0 10px #ddd;
        position: absolute;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #eee;
    }
    .btns {
        display: flex;
        justify-content: flex-end;
    }
    .login_form {
        position: absolute;
        bottom: 0%;
        width: 100%;
        padding: 0 10px;
        box-sizing: border-box;
    }
</style>