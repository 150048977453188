<template>
    <div>
        <el-breadcrumb>
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>物料列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 卡片 -->
        <el-card>
            <el-row :gutter="25">
                <el-col :span="5">
                    <!-- 搜索区域 -->
                    <el-input placeholder="搜索" v-model="searchValue" @keyup.enter.native="search">
                        <el-button slot="append" icon="el-icon-search" @click="search"/>
                    </el-input>
                </el-col>
                <el-col :span="2">
                    <el-button type="primary" @click="drawerAddMat = true">添加物料</el-button>
                </el-col>
            </el-row>
            <el-row>
                <el-table highlight-current-row
                        :data="matList"
                        size="small"
                        @row-dblclick="rowDbClick"
                        max-height="550"
                        border>
                    <el-table-column v-if="showCols.matSku" max-width="120px" prop="matId" label="ID" sortable/>
                    <el-table-column v-if="showCols.matSku" min-width="150px" prop="matSku" label="代码" sortable/>
                    <el-table-column v-if="showCols.matName" min-width="250px" prop="matName" label="名称">
                        <template slot-scope="scope">
                            <el-input v-if="scope.row.edit" v-model="scope.row.originalName" size="small" />
                            <span v-if="!scope.row.edit">{{scope.row.matName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column v-if="showCols.matUnit" max-width="150px" prop="matUnit" label="单位">
                        <template slot-scope="scope">
                            <el-input v-if="scope.row.edit" v-model="scope.row.originalUnit" size="small" />
                            <span v-if="!scope.row.edit">{{scope.row.matUnit}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column v-if="showCols.matType" max-width="150px" prop="matType" label="类型">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.matType === 0" type="info" >
                                子件
                            </el-tag>
                            <el-tag v-if="scope.row.matType === 1" type="success" effect="dark">
                                母件
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="200px">
                        <template slot-scope="scope">
                            <el-button v-if="!scope.row.edit" type="text" @click="scope.row.edit = !scope.row.edit" size="small">编辑</el-button>
                            <el-button v-if="scope.row.edit" type="success" @click="editRow(scope.row)" size="mini">确定</el-button>
                            <el-button v-if="scope.row.edit" type="cancel" @click="editCancel(scope.row)" size="mini">取消</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="page.currentPage"
                        :page-sizes="[10,20, 50, 100]"
                        :page-size="page.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="page.total">
                </el-pagination>
            </el-row>
        </el-card>
        <!-- 添加物料 -->
        <el-drawer  title="添加物料" :visible.sync="drawerAddMat" :with-header="true" direction="ttb" size="400px" @submit.native.prevent>
            <el-form label-position="right" label-width="80px" ref="matAddForm" :model="addForm" :rules="addMatRules" :inline="true">
                <el-form-item label="代码" prop="matSku">
                    <el-input v-model="addForm.matSku"/>
                </el-form-item>
                <el-form-item label="名称" prop="matName">
                    <el-input v-model="addForm.matName"/>
                </el-form-item>
                <el-form-item label="单位" prop="matUnit">
                    <el-input v-model="addForm.matUnit"/>
                </el-form-item>
                <!--<el-form-item label="类别" prop="matType">
                    <el-select v-model="addForm.matType" placeholder="">
                        <el-option label="外购" value="1"/>
                        <el-option label="自产" value="2"/>
                    </el-select>
                </el-form-item>-->
                <el-form-item>
                    <el-button type="primary" @click="addMaterialForm()" native-type="submit">提交</el-button>
                </el-form-item>
            </el-form>
        </el-drawer>
    </div>
</template>
<script>
    export default {
        data () {
            return {
                page: {
                    pageSize: 10,
                    currentPage: 1,
                    total: 0,
                },
                searchValue: "",
                matList:[],
                showCols:{
                    matSku: true,
                    matName: true,
                    matType: true,
                    matUnit: true,
                },
                drawerAddMat: false,
                addForm: {
                    matName: "",
                    matSku: "",
                    matType: ""
                },
                addMatRules: {
                    matName: [
                        {required : true, message: "请输入物料名称", trigger : 'blur'},
                    ],
                    matSku: [
                        {required : true, message: "请输入物料编码", trigger : 'blur'},
                    ],
                    matType: [
                        {required : true, message: "请输入物料类别", trigger : 'blur'},
                    ]
                }
            }
        },
        created() {
            this.getMatList();
        },
        methods:{
            async getMatList() {
                const param = {current: this.page.currentPage, size: this.page.pageSize, search: this.searchValue};
                const {data: res} = await this.$http.post("/mat/list", param);
                if (res.success) {
                    this.matList = res.data.records.map(mat => {
                        // 不这么设置，改变edit属性时，不会切换
                        this.$set(mat, "edit", false);
                        mat.originalName = mat.matName;
                        mat.originalUnit = mat.matUnit;
                        return mat;
                    });
                    this.page.currentPage = res.data.current;
                    this.page.total = res.data.total;
                }
            },
            search() {
                this.page.currentPage = 1;
                this.getMatList();
            },
            editCancel(row) {
                row.originalName = row.matName;
                row.originalUnit = row.matUnit;
                row.edit = false;
            },
            async editRow(row) {
                row.edit = false;
                let flag = false;
                const param = {matId: row.matId};
                if (row.matName !== row.originalName) {
                    param.matName = row.originalName;
                    flag = true;
                }
                if (row.matUnit !== row.originalUnit) {
                    param.matUnit = row.originalUnit
                    flag = true;
                }
                // 有修改，传到后台
                if (flag) {
                    const {data: res} = await this.$http.post("/mat/edit", param);
                    if (res.success) {
                        row.matName = row.originalName;
                        row.matUnit = row.originalUnit;
                        this.$message.success("修改成功");
                    }else {
                        this.$message.error("修改失败：" + res.message)
                    }
                }else {
                    this.$message.info("未修改");
                }
            },
            handleSizeChange(val) {
                this.page.pageSize = val;
                this.getMatList();
            },
            handleCurrentChange(val) {
                this.page.currentPage = val;
                this.getMatList();
            },
            rowDbClick(row, column, event) {
            },
            addMaterialForm() {
                this.$refs.matAddForm.validate(async valid =>{
                    if (valid) {
                        // 提交表单
                        const {data: res} = await this.$http.post("mat/add", this.addForm);
                        if (res.success) {
                            this.drawerAddMat = false;
                            this.$refs.matAddForm.resetFields();
                            this.$message.success("成功！！！");
                            this.getData();
                            return;
                        }
                    }
                    this.$message.error("请检查");
                });
            },
        }
    }
</script>
<style scoped>
    .el-row {
        margin-bottom: 10px;
    }
</style>