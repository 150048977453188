import { render, staticRenderFns } from "./RankItem.vue?vue&type=template&id=b807533a&scoped=true&"
import script from "./RankItem.vue?vue&type=script&lang=js&"
export * from "./RankItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b807533a",
  null
  
)

export default component.exports