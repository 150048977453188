<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>客户列表</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-row :gutter="25">
                <el-col :span="4">
                    <!-- 搜索区域 -->
                    <el-input placeholder="搜索" v-model="searchValue" @keyup.enter.native="search">
                        <el-button slot="append" icon="el-icon-search" @click="search"/>
                    </el-input>
                </el-col>
                <el-col :span="2">
                    <el-button type="primary" @click="drawerVisible = true">新增客户</el-button>
                </el-col>
            </el-row>
            <el-row style="margin-top: 10px">
                <el-table highlight-current-row
                          :data="customerList"
                          size="mini"
                          max-height="550"
                          border>
                    <el-table-column type="expand">
                        <template slot-scope="scope">
                            <el-descriptions size="mini" title="">
                                <template slot="extra">
                                    <el-button type="primary" size="small" style="margin-right: 20px">操作</el-button>
                                </template>
                                <el-descriptions-item label="代码">{{scope.row.customerCode}}</el-descriptions-item>
                                <el-descriptions-item label="名称">{{scope.row.customerName}}</el-descriptions-item>
                                <el-descriptions-item label="法人">{{scope.row.legalPerson}}</el-descriptions-item>
                                <el-descriptions-item label="法定地址">{{scope.row.legalAddress}}</el-descriptions-item>
                                <el-descriptions-item label="办公地址">{{scope.row.address}}</el-descriptions-item>
                                <el-descriptions-item label="联系人">{{scope.row.contacts}}</el-descriptions-item>
                                <el-descriptions-item label="电话">{{scope.row.tel}}</el-descriptions-item>
                                <el-descriptions-item label="邮箱">{{scope.row.mail}}</el-descriptions-item>
                                <el-descriptions-item label="发货地址">{{scope.row.deliverAddress}}</el-descriptions-item>
                                <el-descriptions-item label="发货联系人">{{scope.row.deliverContacts}}</el-descriptions-item>
                                <el-descriptions-item label="发货电话">{{scope.row.deliverTel}}</el-descriptions-item>
                                <el-descriptions-item label="发货邮箱">{{scope.row.deliverMail}}</el-descriptions-item>
                            </el-descriptions>
                        </template>
                    </el-table-column>
                    <el-table-column width="150px" prop="customerId" label="ID"/>
                    <el-table-column min-width="150px" prop="customerCode" label="代码"/>
                    <el-table-column min-width="250px" prop="customerName" label="名称"/>
                    <el-table-column min-width="150px" prop="legalPerson" label="法人"/>
                    <el-table-column min-width="150px" prop="legalAddress" label="法定地址"/>
                </el-table>
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="page.currentPage"
                        :page-sizes="[10,20, 50, 100]"
                        :page-size="page.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="page.total">
                </el-pagination>
            </el-row>
        </el-card>
        <!-- 添加客户 -->
        <el-drawer  title="新增客户信息" :visible.sync="drawerVisible" :with-header="true" direction="ttb" size="400px" @submit.native.prevent>
            <el-form label-position="right" label-width="80px" ref="customerAddForm" :model="addForm" :rules="addCustomerRules" :inline="true">
                <el-form-item label="代码" prop="customerCode">
                    <el-input v-model="addForm.customerCode"/>
                </el-form-item>
                <el-form-item label="名称" prop="customerName">
                    <el-input v-model="addForm.customerName"/>
                </el-form-item>
                <el-form-item label="法人" prop="legalPerson">
                    <el-input v-model="addForm.legalPerson"/>
                </el-form-item>
                <el-form-item label="法定地址" prop="legalPerson">
                    <el-input v-model="addForm.legalPerson"/>
                </el-form-item>
                <el-form-item label="办公地址" prop="legalAddress">
                    <el-input v-model="addForm.legalAddress"/>
                </el-form-item>
                <el-form-item label="联系人" prop="address">
                    <el-input v-model="addForm.address"/>
                </el-form-item>
                <el-form-item label="电话" prop="contacts">
                    <el-input v-model="addForm.contacts"/>
                </el-form-item>
                <el-form-item label="邮箱" prop="tel">
                    <el-input v-model="addForm.tel"/>
                </el-form-item>
                <el-form-item label="发货地址" prop="mail">
                    <el-input v-model="addForm.mail"/>
                </el-form-item>
                <el-form-item label="发货联系人" prop="deliverAddress">
                    <el-input v-model="addForm.deliverAddress"/>
                </el-form-item>
                <el-form-item label="发货电话" prop="deliverTel">
                    <el-input v-model="addForm.deliverTel"/>
                </el-form-item>
                <el-form-item label="发货邮箱" prop="deliverMail">
                    <el-input v-model="addForm.deliverMail"/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="addCustomerForm" native-type="submit">提交</el-button>
                </el-form-item>
            </el-form>
        </el-drawer>
    </div>
</template>

<script>
    export default {
        name: "CustomerList",
        data() {
            return {
                page: {
                    pageSize: 10,
                    currentPage: 1,
                    total: 0,
                },
                searchValue: "",
                customerList: [],
                drawerVisible: false,
                addForm: {
                    customerId: "",
                    customerCode: "",
                    customerName: "",
                    legalPerson: "",
                    legalAddress: "",
                    address: "",
                    contacts: "",
                    tel: "",
                    mail: "",
                    deliverAddress: "",
                    deliverContacts: "",
                    deliverTel: "",
                    deliverMail: "",
                },
                addCustomerRules: {
                    customerCode: [
                        { required: true, message: '请输入客户代码', trigger: 'blur' },
                    ],
                    customerName: [
                        { required: true, message: '请输入客户名称', trigger: 'blur' },
                    ],
                    mail: [
                        { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
                    ],
                    deliverMail: [
                        { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
                    ]
                }
            }
        },
        created() {
            this.getData();
        },
        methods: {
            async getData() {
                const param = {current: this.page.currentPage, size: this.page.pageSize, search: this.searchValue};
                const {data: res} = await this.$http.post("cus/list", param);
                if (res.success) {
                    this.customerList = res.data.records;
                    this.page.total = res.data.total;
                }
            },
            search() {
                this.page.currentPage = 1;
                this.getData();
            },
            handleSizeChange(val) {
                this.page.pageSize = val;
                this.getData();
            },
            handleCurrentChange(val) {
                this.page.currentPage = val;
                this.getData();
            },
            editCustomer(row) {

            },
            addCustomerForm() {
                this.$refs.customerAddForm.validate(async valid => {
                    if (valid) {
                        // 提交表单
                        const {data: res} = await this.$http.post("cus/add", this.addForm);
                        if (res.success) {
                            this.drawerVisible = false;
                            this.$refs.customerAddForm.resetFields();
                            this.$message.success("成功！");
                            this.getData();
                        }else {
                            this.$message.error("失败：" + res.message);
                        }
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>