<template>
    <div>
        欢迎回来
    </div>
</template>
<script>
    export default {

    }
</script>
<style scoped>

</style>