import Vue from 'vue'

export default {

    getMenu: async function() {
        if (!window.sessionStorage.hasOwnProperty("menu")) {
            const {data: res} = await Vue.prototype.$http.get("menu");
            if (res.success) {
                window.sessionStorage.setItem("menu", JSON.stringify(res.data));
                return res.data;
            }
        }else {
            return JSON.parse(window.sessionStorage.getItem("menu"));
        }
    },
    getType0RegionOptions: async function (refresh) {
        if (!window.sessionStorage.hasOwnProperty("type0RegionOptions") || refresh) {
            const {data: res} = await Vue.prototype.$http.get("reg/type_list?type=0");
            if (res.success) {
                window.sessionStorage.setItem("type0RegionOptions", JSON.stringify(res.data));
                return res.data;
            }
        } else {
            return JSON.parse(window.sessionStorage.getItem("type0RegionOptions"));
        }
    },
    getType1RegionOptions: async function (refresh) {
        if (!window.sessionStorage.hasOwnProperty("type1RegionOptions") || refresh) {
            const {data: res} = await Vue.prototype.$http.get("reg/type_list?type=1");
            if (res.success) {
                window.sessionStorage.setItem("type1RegionOptions", JSON.stringify(res.data));
                return res.data;
            }
        } else {
            return JSON.parse(window.sessionStorage.getItem("type1RegionOptions"));
        }
    },


}