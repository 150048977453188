<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>产品列表</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-row class="search-card">
                <el-col :span="4">
                    <!-- 搜索区域 -->
                    <el-input placeholder="搜索" v-model="searchValue" @keyup.enter.native="search">
                        <el-button slot="append" icon="el-icon-search" @click="search"/>
                    </el-input>
                </el-col>
            </el-row>
            <el-row>
                <el-table highlight-current-row
                          :data="productList"
                          size="mini"
                          max-height="550"
                          border>
                    <el-table-column prop="productId" label="ID"/>
                    <el-table-column prop="matSku" label="代码"/>
                    <el-table-column prop="matName" label="名称" min-width="250px"/>
                    <el-table-column prop="productDesc" label="备注" min-width="150px"/>
                    <el-table-column label="操作" width="200px">
                        <template slot-scope="scope">
                            <el-button type="text" @click="showProductItem(scope.row)" size="small">查看</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="page.currentPage"
                        :page-sizes="[10,20, 50, 100]"
                        :page-size="page.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="page.total">
                </el-pagination>
            </el-row>
        </el-card>

        <!-- 弹出显示 -->
        <el-drawer
                size="40%"
                :title="detailDrawerTitle"
                :visible.sync="detailDrawer"
                direction="rtl">
            <el-table :data="rankList" width="100%" :span-method="objectSpanMethod">
                <el-table-column prop="mainName" label="母件名称"/>
                <el-table-column prop="typeName" label="类型"/>
                <el-table-column prop="matSku" label="代码"/>
                <el-table-column prop="matName" label="名称"/>
                <el-table-column prop="ratio1" label="B03"/>
                <el-table-column prop="ratio2" label="B04"/>
            </el-table>
        </el-drawer>
    </div>
</template>

<script>
    export default {
        name: "ProductList",
        data() {
            return {
                page: {
                    pageSize: 20,
                    currentPage: 1,
                    total: 0,
                },
                searchValue: "",
                productList: [],
                detailDrawer: false,
                currentMatId: "",
                detailDrawerTitle: "",
                rankList: []
            }
        },
        created() {
            this.getData();
        },
        methods: {
            async getData() {
                const param = {current: this.page.currentPage, size: this.page.pageSize, search: this.searchValue};
                const {data: res} = await this.$http.post("/product/list", param);
                if (res.success) {
                    this.productList = res.data.records;
                }
            },
            async getRankList() {
                const param = {matId: this.currentMatId};
                const {data: res} = await this.$http.post("product/relative", param);
                if (res.success) {
                    this.rankList = [];
                    const list = res.data.list;
                    for (let i = 0; i < list.length; i++) {
                        const rank = res.data.matData[list[i]];
                        rank.main.mainName = rank.main.matName;
                        rank.main.mainSpan = rank.mzList.length + rank.zList.length + 1;
                        rank.main.rowSpan = 1;
                        this.rankList.push(rank.main);
                        for (let j = 0; j < rank.mzList.length; j++) {
                            let item = rank.mzList[j];
                            if (j === 0) {
                                item.rowSpan = rank.mzList.length;
                            }
                            this.rankList.push(item);
                        }
                        for (let j = 0; j < rank.zList.length; j++) {
                            let item = rank.zList[j];
                            if (j === 0) {
                                item.rowSpan = rank.zList.length;
                            }
                            this.rankList.push(item);
                        }
                    }
                }
            },
            objectSpanMethod({ row, column, rowIndex, columnIndex }) {
                if (columnIndex === 0) {
                    if (row.mainSpan > 0){
                        return {
                            rowspan: row.mainSpan,
                            colspan: 1
                        };
                    } else {
                        return  {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                }
                if (columnIndex === 1) {
                    if (row.rowSpan > 0){
                        return {
                            rowspan: row.rowSpan,
                            colspan: 1
                        };
                    } else {
                        return  {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                }
            },
            search() {
                this.page.currentPage = 1;
                this.getData();
            },
            handleSizeChange(val) {
                this.page.pageSize = val;
                this.getData();
            },
            handleCurrentChange(val) {
                this.page.currentPage = val;
                this.getData();
            },
            showProductItem(row) {
                this.detailDrawerTitle = row.matName;
                this.currentMatId = row.matId;
                this.getRankList();
                this.detailDrawer = true;
            }
        }
    }
</script>

<style scoped>

</style>