<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>流程设计</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-form ref="flowFormRef" :model="flowForm" label-position="left" :rules="flowRules" inline size="medium">
                <el-row>
                    <el-col :span="10">
                        <el-form-item label="单号" prop="orderNo">
                            <el-input placeholder="搜索" v-model="flowForm.orderNo" disabled>
                                <el-button slot="append" icon="el-icon-search" @click="getUnPlanSale"/>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10">
                        <el-form-item label="备注">
                            <el-input v-model="flowForm.remark" disabled/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-button type="primary" @click="submitForm" size="medium">创建订单计划</el-button>
                    </el-col>
                </el-row>
            </el-form>
            <el-table :data="flowForm.list" width="100%">
                <el-table-column label="类型">
                    <template slot-scope="scope">
                        <span v-if="scope.row.matType === 0">子件</span>
                        <span v-if="scope.row.matType === 1">母件</span>
                    </template>
                </el-table-column>
                <el-table-column prop="matSku" label="代码"/>
                <el-table-column prop="matName" label="名称"/>
                <el-table-column prop="matUnit" label="单位"/>
                <el-table-column prop="quantity" label="数量"/>
            </el-table>
        </el-card>
        <el-dialog title="未完成计划列表" :visible.sync="dialog.visible">
            <el-input placeholder="搜索" v-model="dialog.searchValue" @keyup.enter.native="getUnPlanSale">
                <el-button slot="append" icon="el-icon-search" @click="getUnPlanSale"/>
            </el-input>
            <el-table :data="dialog.sales">
                <el-table-column property="orderNo" label="单号"/>
                <el-table-column property="customerName" label="客户"/>
                <el-table-column property="deliverDate" label="交期"/>
                <el-table-column property="remark" label="备注"/>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="itemSelect(scope.row)" type="text" size="medium">
                            选中
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @current-change="getUnPlanSale"
                    :current-page="dialog.page.currentPage"
                    layout="prev, pager, next"
                    :total="dialog.page.total">
            </el-pagination>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "FlowDesign",
        data() {
            return {
                flowForm: {
                    saleId: null,
                    orderNo: "",
                    remark: "",
                    list: [],
                },
                tempForm: {
                    matId: '',
                    matName: '',
                    matSku: '',
                    matUnit: '',
                    quantity: '',
                },
                flowRules: {
                    orderNo: [
                        {required: true, message: "请输入单号", trigger: 'change'}
                    ]
                },
                dialog: {
                    searchValue: '',
                    visible: false,
                    sales: [],
                    page: {
                        currentPage: 1,
                        total: 0
                    }
                }
            }
        },
        methods: {
            async getUnPlanSale() {
                this.dialog.page.currentPage = 1;
                this.dialog.visible = true;
                // 从后台获取数据
                const param = {search: this.dialog.searchValue, type: 1, status: 0, current: 1, size: 20};
                const {data: res} = await this.$http.post("sale/list", param);
                if (res.success) {
                    this.dialog.sales = res.data.records;
                    this.dialog.page.total = res.data.total;
                }
            },
            async itemSelect(item) {
                this.flowForm.saleId = item.saleId;
                this.flowForm.orderNo = item.orderNo;
                this.flowForm.remark = item.remark;
                const {data: res} = await this.$http.post("sale/detail", {saleId: item.saleId});
                if (res.success) {
                    this.flowForm.list = res.data.items;
                }
                this.dialog.visible = false;
            },
            submitForm() {
                if (this.flowForm.saleId == null) {
                    this.$message.error("请选择订单");
                    return;
                }
                this.$refs.flowFormRef.validate(async valid => {
                    if (valid) {
                        const param = {saleId: this.flowForm.saleId};
                        const {data: res} = await this.$http.post("flow/add", param);
                        if (res.success) {
                            this.$message.success("创建成功");
                            this.$refs.flowFormRef.resetFields();
                            this.flowForm.list = [];
                        }else {
                            this.$message.error(res.message);
                        }
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>