import Vue from 'vue'
import VueRouter from 'vue-router'
// 引用页面
import Login from '../components/Login.vue'
import Home from '../components/Home.vue'
import Welcome from "../components/Welcome";
import MatList from "../components/base/MatList";
import RegList from "../components/base/RegList";
import CustomerList from "../components/base/CustomerList";
import SupplierList from "../components/base/SupplierList";
import RankDesign from "../components/rank/RankDesign";
import RankList from "../components/rank/RankList";
import RankItem from "../components/rank/RankItem";
import FlowDesign from "../components/flow/FlowDesign";
import FlowList from  "../components/flow/FlowList";
import FlowDetail from "../components/flow/FlowDetail";
import ProductDesign from "../components/product/ProductDesign";
import ProductList from  "../components/product/ProductList";
import ProductEdit from  "../components/product/ProductEdit";
import SaleDesign from  "../components/sale/SaleDesign";
import SaleDetail from  "../components/sale/SaleDetail";
import SaleList from  "../components/sale/SaleList";
import SaleSplit from  "../components/sale/SaleSplit";
import PropList from "../components/prop/PropList";
import PoolData from "../components/pool/PoolData";



Vue.use(VueRouter);

const routes = [
  {
    path:"/",
    redirect: "/home"
  },
  {
    path: "/login",
    component: Login
  },
  {
    path: "/home",
    component: Home,
    redirect: "/welcome",
    children :[
      {path: "/welcome", component: Welcome},
      {path: "/mat", component: MatList},
      {path: "/reg", component: RegList},
      {path: "/cus", component: CustomerList},
      {path: "/sup", component: SupplierList},
      {path: "/rank_design", component: RankDesign},
      {path: "/ranks", component: RankList, keepAlive: true},
      {path: "/rank_item", component: RankItem},
      {path: "/flow_design", component: FlowDesign},
      {path: "/flows", component: FlowList, keepAlive: true},
      {path: "/flow_detail", component: FlowDetail},
      {path: "/product_design", component: ProductDesign},
      {path: "/products", component: ProductList},
      {path: "/product_edit", component: ProductEdit},
      {path: "/sale_design", component: SaleDesign},
      {path: "/sales", component: SaleList, keepAlive: true},
      {path: "/sale_detail", component: SaleDetail},
      {path: "/sale_split", component: SaleSplit},
      {path: "/prop_list", component: PropList},
      {path: "/pool_data", component: PoolData},
    ]
  },
]

const router = new VueRouter({
  routes
})

/**
 * to :   要去的路由
 * from:  从哪里来的路由
 * next:  处理方法，next(url) 重定向， 没写则去to的路由
 */
router.beforeEach((to,from, next) => {
  // 访问登陆页不处理
  if (to.path === '/login') return next();
  // 访问其他页面，判断user对象
  const user = window.sessionStorage.getItem("user");
  if (!user) return next('/login');
  next();
})

export default router
