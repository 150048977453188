<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>区间列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 卡片 -->
        <el-card>
            <el-row :gutter="25">
                <el-col :span="5">
                    <!-- 搜索区域 -->
                    <el-input placeholder="搜索" v-model="searchValue" @keyup.enter.native="search">
                        <el-button slot="append" icon="el-icon-search" @click="search"/>
                    </el-input>
                </el-col>
                <el-col :span="2">
                    <el-button type="primary" @click="drawerAddMat = true">添加区间</el-button>
                </el-col>
            </el-row>
            <el-row>
                <el-table highlight-current-row
                        :data="regList"
                        size="mini"
                        @row-dblclick="rowDbClick"
                        max-height="550"
                        border>
                    <el-table-column width="150px" prop="regionId" label="ID" sortable/>
                    <el-table-column width="150px" prop="regionCode" label="编码" sortable/>
                    <el-table-column min-width="250px" label="名称">
                        <template slot-scope="scope">
                            <el-input v-if="scope.row.edit" v-model="scope.row.originalName" size="small" />
                            <span v-if="!scope.row.edit">{{scope.row.regionName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column max-width="150px" label="顺序">
                        <template slot-scope="scope">
                            <el-input v-if="scope.row.edit" v-model="scope.row.originalIndex" size="small" />
                            <span v-if="!scope.row.edit">{{scope.row.regionIndex}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column max-width="150px" label="产出时间">
                        <template slot-scope="scope">
                            <el-input v-if="scope.row.edit" v-model="scope.row.originalDay" size="small" />
                            <span v-if="!scope.row.edit">{{scope.row.outputDay}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column max-width="150px" label="类型">
                        <template slot-scope="scope">
                            <span v-if="scope.row.regionType === 0">库</span>
                            <span v-if="scope.row.regionType === 1">区间</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="200px">
                        <template slot-scope="scope">
                            <el-button v-if="!scope.row.edit" type="text" @click="scope.row.edit = !scope.row.edit" size="small">编辑</el-button>
                            <el-button v-if="scope.row.edit" type="success" @click="editRow(scope.row)" size="mini">确定</el-button>
                            <el-button v-if="scope.row.edit" type="cancel" @click="editCancel(scope.row)" size="mini">取消</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="page.currentPage"
                        :page-sizes="[10,20, 50, 100]"
                        :page-size="page.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="page.total">
                </el-pagination>
            </el-row>
        </el-card>
        <!-- 添加物料 -->
        <el-drawer  title="添加区间" :visible.sync="drawerAddMat" :with-header="true" direction="ttb" size="400px" @submit.native.prevent>
            <el-form label-position="right" label-width="80px" ref="matAddForm" :model="addForm" :rules="addMatRules" :inline="true">
                <el-form-item label="编码" prop="regionCode">
                    <el-input v-model="addForm.regionCode"/>
                </el-form-item>
                <el-form-item label="名称" prop="regionName">
                    <el-input v-model="addForm.regionName"/>
                </el-form-item>
                <el-form-item label="顺序" prop="outputDay">
                    <el-input type="number" step="0" v-model="addForm.regionIndex"/>
                </el-form-item>
                <el-form-item label="产出时间" step="0" prop="outputDay">
                    <el-input v-model="addForm.outputDay"/>
                </el-form-item>
                <el-form-item label="类别" prop="regionType">
                    <el-select v-model="addForm.regionType" placeholder="">
                        <el-option label="库" value="0"/>
                        <el-option label="区间" value="1"/>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="addRegionForm()" native-type="submit">提交</el-button>
                </el-form-item>
            </el-form>
        </el-drawer>
    </div>
</template>
<script>
    export default {
        data () {
            return {
                page: {
                    pageSize: 10,
                    currentPage: 1,
                    total: 0,
                },
                searchValue: "",
                regList:[],
                drawerAddMat: false,
                addForm: {
                    regionCode: "",
                    regionName: "",
                    regionIndex: "",
                    outputDay: "",
                    regionType: "",
                },
                addMatRules: {
                    regionCode: [
                        {required : true, message: "请输入区间编码", trigger : 'blur'},
                    ],
                    regionName: [
                        {required : true, message: "请输入区间名称", trigger : 'blur'},
                    ]
                }
            }
        },
        created() {
            this.getRegList();
        },
        methods:{
            async getRegList() {
                const param = {current: this.page.currentPage, size: this.page.pageSize, search: this.searchValue};
                const {data: res} = await this.$http.post("/reg/list", param);
                if (res.success) {
                    this.regList = res.data.records.map(reg => {
                        // 不这么设置，改变edit属性时，不会切换
                        this.$set(reg, "edit", false);
                        reg.originalName = reg.regionName;
                        reg.originalIndex = reg.regionIndex;
                        reg.originalDay = reg.outputDay;
                        return reg;
                    });
                    this.page.currentPage = res.data.current;
                    this.page.total = res.data.total;
                }
            },
            search() {
                this.page.currentPage = 1;
                this.getRegList();
            },
            editCancel(row) {
                row.originalName = row.matName;
                row.originalUnit = row.matUnit;
                row.edit = false;
            },
            async editRow(row) {
                row.edit = false;
                let flag = false;
                const param = {regionId: row.regionId};
                if (row.regionName !== row.originalName) {
                    flag = true;
                    param.regionName = row.originalName;
                }
                if (row.regionIndex !== row.originalIndex) {
                    flag = true;
                    param.regionIndex = row.originalIndex;
                }
                if (row.outputDay !== row.originalDay) {
                    flag = true;
                    param.outputDay = row.originalDay;
                }
                // 有修改，传到后台
                if (flag) {
                    const {data: res} = await this.$http.post("/reg/edit", param);
                    if (res.success) {
                        row.regionName = row.originalName;
                        row.regionIndex = row.originalIndex;
                        row.outputDay = row.originalDay;
                        this.$message.success("修改成功");
                    }else {
                        this.$message.error("修改失败：" + res.message)
                    }
                }else {
                    this.$message.info("未修改");
                }
            },
            handleSizeChange(val) {
                this.page.pageSize = val;
                this.getRegList();
            },
            handleCurrentChange(val) {
                this.page.currentPage = val;
                this.getRegList();
            },
            rowDbClick(row, column, event) {
            },
            addRegionForm() {
                this.$refs.matAddForm.validate(async valid =>{
                    if (valid) {
                        // 提交表单
                        const {data: res} = await this.$http.post("reg/add", this.addForm);
                        if (res.success) {
                            this.drawerAddMat = false;
                            this.$refs.matAddForm.resetFields();
                            this.$message.success("成功！！！");
                            // 清除区间、库的选项
                            window.sessionStorage.removeItem("type0RegionOptions");
                            window.sessionStorage.removeItem("type1RegionOptions");
                            return;
                        }
                    }
                    this.$message.error("请检查");
                });
            },
        }
    }
</script>
<style scoped>
    .el-row {
        margin-bottom: 10px;
    }
    .el-breadcrumb {
        margin-bottom: 15px;
        font-size: 17px;
    }
</style>