
<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item><a href="javascript:history.back(-1)">阶列表</a></el-breadcrumb-item>
            <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <!-- 描述框 -->
            <el-descriptions title="阶信息" border>
                <template slot="extra">
                    <el-button type="primary" size="small" @click="copyRank">复制</el-button>
                </template>
                <el-descriptions-item label="编码">{{mat.matSku}}</el-descriptions-item>
                <el-descriptions-item label="名称" :span="2">{{mat.matName}}</el-descriptions-item>
                <el-descriptions-item label="默认库">
                    <el-select v-model="item.type0RegionId" filterable placeholder="请选择" @change="changeType0Region">
                        <el-option
                                v-for="item in type0RegionOptions"
                                :label="item.regionName"
                                :value="item.regionId">
                        </el-option>
                    </el-select>
                </el-descriptions-item>
                <el-descriptions-item label="默认区间">
                    <el-select v-model="item.defaultRegionId" filterable placeholder="请选择" @change="changeType1Region">
                        <el-option
                                v-for="item in item.type1Regions"
                                :key="item.regionId"
                                :label="item.regionName"
                                :value="item.regionId">
                        </el-option>
                    </el-select>
                </el-descriptions-item>
            </el-descriptions>
            <!-- 默认区间（单选） -->

            <!-- 其他区间（穿梭框） -->
            <div class="el-card__header">
                区间信息
                <el-button type="primary" size="small" @click="dialog.visible = true">增加区间</el-button>
            </div>
            <el-table
                    :data="item.type1Regions"
                    stripe
                    style="width: 100%">
                <el-table-column prop="regionCode" label="区间代码" />
                <el-table-column prop="regionName" label="区间名称" />
                <el-table-column prop="status" label="状态" />
                <el-table-column prop="defaultRegion" label="默认" />
            </el-table>

            <!-- 子件列表（表格） -->
            <div class="el-card__header">配件信息</div>
            <el-table
                    :data="item.list"
                    stripe
                    style="width: 100%">
                <el-table-column
                        prop="matSku"
                        label="编码"
                        min-width="180">
                </el-table-column>
                <el-table-column
                        prop="matName"
                        label="姓名"
                        min-width="250">
                </el-table-column>
                <el-table-column
                        prop="itemType"
                        label="类型">
                </el-table-column>
                <el-table-column
                        prop="ratio1"
                        label="系数1">
                </el-table-column>
                <el-table-column
                        prop="ratio2"
                        label="系数2">
                </el-table-column>
            </el-table>
        </el-card>
        <el-dialog
                title="增加产品"
                :visible.sync="dialog.visible"
                width="30%">
            <el-form label-width="80px" ref="choiceFormRef" :rules="dialog.choiceFormRules" :model="dialog.choiceForm">
                <el-form-item label="其他区间">
                    <el-select
                            v-model="dialog.regionId"
                            filterable
                            placeholder="请选择">
                        <el-option
                                v-for="item in type1RegionOptions"
                                :key="item.value"
                                :label="item.regionName"
                                :value="item.regionId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitChoiceForm()">确定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                matId: null,
                item: {},
                regions: [],
                mat: {},
                type0RegionOptions: [],
                type1RegionOptions: [],
                dialog: {
                    visible: false,
                    regionId: '',
                }
            }
        },
        created() {
            this.matId = this.$route.query.id;
            this.getData();
        },
        methods: {
            async submitChoiceForm() {
                if (this.dialog.regionId === '') {
                    this.$message.warning("未选择区间");
                    return;
                }
                this.$confirm('确定要增加区间吗 ?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    const param = {matId: this.matId, edit: 'addRegion', itemRegionId: this.dialog.regionId};
                    await this.editRequest(param);
                    this.getData();
                    this.dialog.visible = false;
                }).catch(() => {

                });
            },
            async copyRank() {
                await this.$router.push({path: '/rank_design', query: {item: this.item, type: 'COPY'}});
            },
            async getData() {
                const param = {matId: this.matId};
                const {data: res} = await this.$http.post("/rank/item", param);
                if (res.success) {
                    // 拆分数据
                    this.mat = res.data.mat;
                    this.item.type0RegionId = res.data.grMain.regionId;
                    this.item.defaultRegionId = res.data.defaultMain.regionId;
                    this.item.type1Regions = res.data.grItems;
                    this.item.list = res.data.ylItems;
                }
                this.type0RegionOptions = await this.sessionStorageData.getType0RegionOptions();
                this.type1RegionOptions = await this.sessionStorageData.getType1RegionOptions();
                // 对已经选择的区间剔除
                for (let i = 0; i < this.item.type1Regions.length; i++) {
                    const regionId = this.item.type1Regions[i].regionId;
                    for (let j = 0; j < this.type1RegionOptions.length; j++) {
                        if (this.type1RegionOptions[j].regionId === regionId) {
                            this.type1RegionOptions.splice(j,1);
                        }
                    }
                }
            },
            changeType0Region(item) {
                this.$confirm('确定要修改默认库吗 ?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    const param = {matId: this.matId, edit: 'type0', type0RegionId: item};
                    this.editRequest(param);
                }).catch(() => {});
            },
            async changeType1Region(item) {
                this.$confirm('确定要修改默认区间吗 ?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    const param = {matId: this.matId, edit: 'defaultRegion', defaultRegionId: item};
                    console.log(param)
                    this.editRequest(param);
                }).catch(() => {});
            },
            async editRequest(param) {
                const {data: res} = await this.$http.post("/rank/edit", param);
                if (res.success) {
                    this.$forceUpdate();
                }
            }
        }
    }
</script>

<style scoped>

</style>