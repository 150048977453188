<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>阶设计</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-form ref="rankFormRef" :model="rankForm" label-position="left" :rules="rankRules" size="medium">
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="母件"  prop="matName" required>
                            <el-autocomplete
                                    v-model="rankForm.matName"
                                    :fetch-suggestions="matSearch"
                                    placeholder="请输入内容"
                                    :trigger-on-focus="false"
                                    @select="mainMatSelect">
                                <template slot-scope="{ item }">
                                    <div>{{ item.matName }}</div>
                                    <span>{{ item.matSku }}</span>
                                </template>
                            </el-autocomplete>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-button type="primary" @click="submitForm" size="medium">创建阶</el-button>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6" required>
                        <el-form-item label="默认库" prop="type0RegionId" required>
                            <el-select v-model="rankForm.type0RegionId" filterable placeholder="请选择">
                                <el-option
                                        v-for="item in type0RegionOptions"
                                        :key="item.value"
                                        :label="item.regionName"
                                        :value="item.regionId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="默认区间" prop="defaultRegionId" required>
                            <el-select v-model="rankForm.defaultRegionId" filterable placeholder="请选择" @change="defaultRegionChange">
                                <el-option
                                        v-for="item in type1RegionOptions"
                                        :key="item.value"
                                        :label="item.regionName"
                                        :value="item.regionId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="其他区间">
                            <el-select
                                    v-model="rankForm.type1Regions"
                                    multiple
                                    filterable
                                    placeholder="请选择">
                                <el-option
                                        v-for="item in type1RegionOptions"
                                        :key="item.value"
                                        :label="item.regionName"
                                        :value="item.regionId"
                                        :disabled="item.regionId === rankForm.defaultRegionId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-card>
                    <el-form ref="itemFormRef" :model="itemForm" :rules="itemRules">
                        <el-row>
                            <el-col :span="24">
                                <el-form-item label="配件" prop="itemMatName" required>
                                    <el-autocomplete
                                            style="width: 53.5rem"
                                            popper-class="my-autocomplete"
                                            v-model="itemForm.itemMatName"
                                            :fetch-suggestions="matSearch"
                                            placeholder="请输入内容"
                                            :trigger-on-focus="false"
                                            @select="itemSelect">
                                        <template slot-scope="{ item }">
                                            <div class="name">{{ item.matName }}</div>
                                            <span class="addr">{{ item.matSku }}</span>
                                        </template>
                                    </el-autocomplete>
                                </el-form-item>
                            </el-col>
                            <el-col :span="7">
                                <el-form-item label="类型" prop="itemType" required>
                                    <el-radio-group v-model="itemForm.itemType">
                                        <el-radio-button v-for="item in itemTypes" :label="item.value">{{item.label}}</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="B03" prop="b03" required>
                                    <el-input type="number" v-model="itemForm.b03" placeholder="B03" style="width: 80%"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="B04" prop="b04" required>
                                    <el-input type="number" v-model="itemForm.b04" placeholder="B04" style="width: 80%"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3">
                                <el-button @click="addRankItem" size="medium">增加</el-button>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-card>
                <el-row>
                    <el-col :span="24">
                        <el-table :data="rankForm.list" width="100%">
                            <el-table-column prop="matSku" label="代码"/>
                            <el-table-column prop="matName" label="名称"/>
                            <el-table-column prop="itemType" label="类型"/>
                            <el-table-column prop="b03" label="B03"/>
                            <el-table-column prop="b04" label="B04"/>
                            <el-table-column fixed="right" label="操作">
                                <template slot-scope="scope">
                                    <el-button @click.native.prevent="deleteItem(scope.$index, rankForm.list)" type="text" size="medium">
                                        删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                itemTypes: [
                    {
                        value: 'MZ',
                        label: '母子件'
                    },
                    {
                        value: 'Z',
                        label: '子件'
                    }
                ],
                type0RegionOptions: [],
                type1RegionOptions: [],
                rankForm: {
                    matName: "",
                    matId: "",
                    matSku: "",
                    type0RegionId: "",
                    defaultRegionId: "",
                    type1Regions: [],
                    list: []
                },
                rankRules: {
                    matName: [
                        {required : true, message: "请选择母件", trigger : 'blur'},
                    ],
                    type0RegionId: [
                        {required : true, message: "请选择默认库", trigger : 'blur'}
                    ],
                    defaultRegionId: [
                        {required : true, message: "请选择默认区间", trigger : 'blur'}
                    ]
                },
                itemForm: {
                    itemMatName: "",
                    itemMatSku: "",
                    itemMatId: "",
                    itemType: "MZ",
                    b03: "",
                    b04: "",
                },
                itemRules: {
                    itemMatName: [
                        {required : true, message: "请选择配件", trigger : 'blur'},
                    ],
                    itemType: [
                        {required : true, message: "请选择类型", trigger : 'blur'},
                    ],
                    b03: [
                        {required : true, message: "请输入B03", trigger : 'blur'},
                    ],
                    b04: [
                        {required : true, message: "请输入B04", trigger : 'blur'},
                    ],
                },
            }
        },
        created() {
            this.getBaseInfo();
            const query = this.$route.query;
            if (query !== undefined && typeof query.item === 'object' && query.type === 'COPY') {
                const item = this.$route.query.item;
                this.rankForm.type0RegionId = item.type0RegionId;
                this.rankForm.defaultRegionId = item.defaultRegionId;
                let type1Regions = item.type1Regions.map(regionItem => {
                    return regionItem.regionId;
                });
                type1Regions.splice(type1Regions.indexOf(this.rankForm.defaultRegionId), 1);
                this.rankForm.type1Regions = type1Regions;
                this.rankForm.list = item.list;
            }
            if (query !== undefined && typeof query.item === 'object' && query.type === 'CREATE') {
                this.mainMatSelect(query.mat);
            }
        },
        methods: {
            async getBaseInfo() {
                this.type0RegionOptions = await this.sessionStorageData.getType0RegionOptions();
                this.type1RegionOptions = await this.sessionStorageData.getType1RegionOptions();
            },
            mainMatSelect(item) {
                this.rankForm.matId = item.matId;
                this.rankForm.matName = item.matName;
            },
            itemSelect(item) {
                this.itemForm.itemMatId = item.matId;
                this.itemForm.itemMatName = item.matName;
                this.itemForm.itemMatSku = item.matSku;
                if (item.matType === 0) {
                    this.itemForm.itemType = 'Z';
                }else if (item.itemType === 1) {
                    this.itemForm.itemType = 'MZ';
                }
            },
            async matSearch(str, callback) {
                let data = [];
                // 从后台获取数据
                const param = {search: str, current:1, size: 20};
                const {data: res} = await this.$http.post("mat/list", param);
                if (res.success) {
                    data = res.data.records.map(mat => {
                        return {matId: mat.matId, matName: mat.matName, matSku: mat.matSku, matType: mat.matType};
                    });
                }
                callback(data);
            },
            addRankItem() {
                // 完成性
                this.$refs.itemFormRef.validate(valid => {
                    if(valid) {
                        // 做验证判断
                        const list = this.rankForm.list;
                        for (let i=0; i<list.length; i++) {
                            if (list[i].matId === this.itemForm.itemMatId) {
                                this.$alert("已存在相同的器件");
                                return;
                            }
                        }
                        let item = {matId: this.itemForm.itemMatId, matName: this.itemForm.itemMatName, matSku: this.itemForm.itemMatSku,
                            itemType: this.itemForm.itemType, b03: this.itemForm.b03, b04: this.itemForm.b04};
                        this.rankForm.list.push(item);
                        this.$refs.itemFormRef.resetFields();
                    }
                });
            },
            deleteItem(index, rows) {
                rows.splice(index, 1);
            },
            defaultRegionChange(item) {
                const index = this.rankForm.type1Regions.indexOf(this.rankForm.defaultRegionId);
                if (index >= 0) {
                    this.rankForm.type1Regions.splice(index, 1);
                }
            },
            submitForm() {
                if (this.rankForm.list.length < 1) {
                    this.$message.warning("阶组件未选择");
                    return;
                }
                this.$refs.rankFormRef.validate(async valid => {
                    if (valid) {
                        const {data: res} = await this.$http.post("rank/add", this.rankForm);
                        if (res.success) {
                            this.$message.success("创建成功");
                            this.$refs.rankFormRef.resetFields();
                            this.rankForm.list = [];
                        }
                    }else {
                        this.$message.error("验证失败");
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>