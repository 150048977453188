<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>产品审核</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-form ref="productFormRef" :model="productForm" label-position="left">
                <el-row>
                    <el-col :span="10">
                        <el-form-item label="母件" required>
                            <el-input style="width: 88%" v-model="productForm.matName" disabled>
                                <el-button slot="append" icon="el-icon-search" @click="getUnCheckRank"/>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10">
                        <el-form-item label="备注">
                            <el-input style="width: 80%" v-model="productForm.productDesc"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item>
                            <el-button type="primary" @click="submitForm" size="medium">
                                审核
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-table :data="rankList" width="100%" border :span-method="objectSpanMethod">
                <el-table-column prop="mainName" label="母件名称"/>
                <el-table-column prop="typeName" label="类型"/>
                <el-table-column label="代码">
                    <template slot-scope="scope">
                        <span>{{scope.row.matSku}}</span>
                        <span v-if="scope.row.bug" style="color: red">（未创建）</span>
                    </template>
                </el-table-column>
                <el-table-column prop="matName" label="名称"/>
                <el-table-column prop="ratio1" label="B03"/>
                <el-table-column prop="ratio2" label="B04"/>
            </el-table>
        </el-card>
        <el-dialog title="未审核列表" :visible.sync="dialog.visible">
            <el-input placeholder="搜索" v-model="dialog.searchValue" @keyup.enter.native="getUnCheckRank">
                <el-button slot="append" icon="el-icon-search" @click="getUnCheckRank"/>
            </el-input>
            <el-table :data="dialog.mats">
                <el-table-column property="matId" label="ID"/>
                <el-table-column property="matSku" label="编码"/>
                <el-table-column property="matName" label="名称"/>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="itemSelect(scope.row)" type="text" size="medium">
                            选中
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @cureent-change="getUnCheckRank"
                    :current-page="dialog.page.currentPage"
                    layout="prev, pager, next"
                    :total="dialog.page.total">
            </el-pagination>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "ProductDesign",
        data() {
            return {
                complete: false,
                productForm: {
                    matId: "",
                    matName: "",
                    matSku: "",
                    productDesc: "",
                },
                rankList: [],
                bug: false,
                dialog: {
                    searchValue: '',
                    visible: false,
                    mats: [],
                    page: {
                        currentPage: 1,
                        total: 0
                    }
                }
            }
        },
        methods: {
            async getUnCheckRank() {
                this.dialog.page.currentPage = 1;
                this.dialog.visible = true;
                // 从后台获取数据
                const param = {search: this.dialog.searchValue, type: 1, status: 0, current: 1, size: 20};
                const {data: res} = await this.$http.post("rank/un_check", param);
                if (res.success) {
                    this.dialog.mats = res.data.records;
                    this.dialog.page.total = res.data.total;
                }
            },
            async getData() {
                const param = {matId: this.productForm.matId};
                const {data: res} = await this.$http.post("product/relative", param);
                if (res.success) {
                    const list = res.data.list;
                    for (let i = 0; i < list.length; i++) {
                        const rank = res.data.matData[list[i]];
                        rank.main.mainName = rank.main.matName;
                        rank.main.mainSpan = rank.mzList.length + rank.zList.length + 1;
                        rank.main.rowSpan = 1;
                        this.rankList.push(rank.main);
                        for (let j = 0; j < rank.mzList.length; j++) {
                            let item = rank.mzList[j];
                            if (j === 0) {
                                item.rowSpan = rank.mzList.length;
                            }
                            this.rankList.push(item);
                        }
                        for (let j = 0; j < rank.zList.length; j++) {
                            let item = rank.zList[j];
                            if (j === 0) {
                                item.rowSpan = rank.zList.length;
                            }
                            this.rankList.push(item);
                        }
                    }
                    this.bug = res.data.bug;
                }
            },
            async matSearch(str, callback) {
                let data = [];
                // 从后台获取数据
                const param = {search: str, type: 1, current: 1, size: 20};
                const {data: res} = await this.$http.post("mat/list", param);
                if (res.success) {
                    data = res.data.records.map(mat => {
                        return {matId: mat.matId, matName: mat.matName, matSku: mat.matSku, matUnit: mat.matUnit};
                    })
                }
                callback(data);
            },
            itemSelect(item) {
                this.productForm.matId = item.matId;
                this.productForm.matSku = item.matSku;
                this.productForm.matName = item.matName;
                this.rankList = [];
                this.dialog.visible = false;
                this.getData();
            },
            objectSpanMethod({ row, column, rowIndex, columnIndex }) {
                if (columnIndex === 0) {
                    if (row.mainSpan > 0){
                        return {
                            rowspan: row.mainSpan,
                            colspan: 1
                        };
                    } else {
                        return  {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                }
                if (columnIndex === 1) {
                    if (row.rowSpan > 0){
                        return {
                            rowspan: row.rowSpan,
                            colspan: 1
                        };
                    } else {
                        return  {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                }
            },
            submitForm() {
                if (this.bug) {
                    this.$message.error("阶设计未完整，请检查");
                    return;
                }
                this.$refs.productFormRef.validate(async valid => {
                    if (valid) {
                        const {data: res} = await this.$http.post("product/add", this.productForm);
                        if (res.success) {
                            this.$message.success("创建成功");
                            this.$refs.productFormRef.resetFields();
                            this.rankList = [];
                        }else {
                            this.$message({
                                message: res.message,
                                type: "error",
                                showClose: true,
                                duration: 0
                            })
                        }
                    }
                });
            },
        }
    }
</script>

<style scoped>

</style>