import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
// 引入全局样式
import './assets/css/global.css'
// 引入icon
import './assets/iconfont/iconfont.css'
// 导入跨域请求
import axios from "axios";
// 引入localStorage管理方法
import SessionStorageData from "./utils/SessionStorageData";
// 引入Loading
import { Loading } from 'element-ui';

// 引用sessionStorage的方法
Vue.prototype.sessionStorageData = SessionStorageData;

// 挂载默认访问路径
Vue.prototype.$http = axios;

if (process.env.NODE_ENV === 'production') {
  // 生产环境
  axios.defaults.baseURL = 'http://www.numsflow.com:8080/nums_bg';
}else if (process.env.NODE_ENV === 'development'){
  // 开发环境
  axios.defaults.baseURL = 'http://localhost:8080/nums_bg';
}


axios.defaults.headers = {
  "Content-type": "application/json;charset=utf-8",
};
// http request拦截器 添加一个请求拦截器
axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  let token = window.localStorage.getItem("token");
  if (token) {
    config.headers.token = token;    //将token放到请求头发送给服务器
    //这里经常搭配token使用，将token值配置到tokenkey中，将tokenkey放在请求头中
    //config.headers['token'] = Token;
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});


Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
