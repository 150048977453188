<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>创建订单</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-form ref="saleFormRef" :model="saleForm" label-position="left" :rules="saleFormRules" inline size="medium">
                <el-row>
                    <el-col :span="10">
                        <el-form-item label="单号" prop="orderNo">
                            <el-input v-model="saleForm.orderNo"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" required>
                        <el-form-item label="客户" prop="cusId" required>
                            <el-select v-model="saleForm.cusId" filterable placeholder="请选择">
                                <el-option
                                        v-for="item in cusList"
                                        :key="item.customerId"
                                        :label="item.customerName"
                                        :value="item.customerId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-button type="primary" @click="submitForm" size="medium">创建订单</el-button>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="10" required>
                        <el-form-item label="预计交期" prop="deliverDate">
                            <el-date-picker
                                    value-format="yyyy-MM-dd"
                                    v-model="saleForm.deliverDate"
                                    type="date"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10">
                        <el-form-item label="备注">
                            <el-input v-model="saleForm.remark"/>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-form ref="tempFormRef" :model="tempForm" :rules="tempRules">
                <el-row>
                    <el-col :span="4">
                        <el-form-item prop="type">
                            <el-radio-group v-model="tempForm.matType">
                                <el-radio-button :label="1">母件</el-radio-button>
                                <el-radio-button :label="0">子件</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="产品" prop="matName">
                            <el-autocomplete
                                    style="width: 80%"
                                    popper-class="my-autocomplete"
                                    v-model="tempForm.matName"
                                    :fetch-suggestions="matSearch"
                                    placeholder="请输入内容"
                                    :trigger-on-focus="false"
                                    @select="itemSelect">
                                <template slot-scope="{ item }">
                                    <div class="name">{{ item.matName }}</div>
                                    <span class="addr">{{ item.matSku }}</span>
                                </template>
                            </el-autocomplete>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="数量" prop="quantity">
                            <el-input type="number" v-model="tempForm.quantity" style="width: 80%"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="价格" prop="price">
                            <el-input type="number" v-model="tempForm.price" style="width: 80%"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="2">
                        <el-button @click="addRankItem" size="medium">增加</el-button>
                    </el-col>
                </el-row>
            </el-form>
            <el-table :data="saleForm.list" width="100%">
                <el-table-column label="类型">
                    <template slot-scope="scope">
                        <span v-if="scope.row.matType === 0">子件</span>
                        <span v-if="scope.row.matType === 1">母件</span>
                    </template>
                </el-table-column>
                <el-table-column prop="matSku" label="代码"/>
                <el-table-column prop="matName" label="名称"/>
                <el-table-column prop="quantity" label="数量"/>
                <el-table-column prop="price" label="价格"/>
                <el-table-column fixed="right" label="操作">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="deleteItem(scope.$index)" type="text" size="medium">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
    export default {
        name: "FlowDesign",
        data() {
            return {
                saleForm: {
                    orderNo: '',
                    remark: '',
                    cusId: '',
                    deliverDate: '',
                    list: [],
                },
                cusList: [],
                tempForm: {
                    matType: 1,
                    matId: '',
                    matName: '',
                    matSku: '',
                    matUnit: '',
                    quantity: 0.0,
                    price: 0.0,
                },
                saleFormRules: {
                    cusId: [
                        {required: true, message: "请选择客户", trigger: 'blur'}
                    ],
                    deliverDate: [
                        {required: true, message: "请选择预计交期", trigger: 'blur'}
                    ]
                },
                tempRules: {
                    matName: [
                        {required: true, message: "请选择母件", trigger: 'blur'}
                    ],
                    quantity: [
                        {required: true, message: "请输入数量", trigger: 'blur'},
                    ],
                    price: [
                        {required: true, message: "请输入价格", trigger: 'blur'},
                    ]
                }
            }
        },
        created() {
            this.getCustomerList();
            // 获取信息
            if (this.$route.query !== undefined && typeof this.$route.query.saleItems === 'object') {
                const sale = this.$route.query.sale;
                const saleItems = this.$route.query.saleItems;
                this.saleForm.cusId = sale.cusId;
                this.saleForm.list = [];
                for (let i = 0; i < saleItems.length; i++) {
                    const item = {
                        matId: saleItems[i].matId,
                        matName: saleItems[i].matName,
                        matSku: saleItems[i].matSku,
                        quantity: saleItems[i].quantity,
                        price: saleItems[i].price,
                        matType: saleItems[i].matType,
                    };
                    this.saleForm.list.push(item);
                }
            }
        },
        methods: {
            async getCustomerList() {
                const {data: res} = await this.$http.post("cus/all");
                if (res.success) {
                    this.cusList = res.data;
                }
            },
            deleteItem(index) {
                this.saleForm.list.splice(index, 1);
            },
            async matSearch(str, callback) {
                let data = [];
                // 从后台获取数据
                const param = {search: str, type: this.tempForm.matType, current: 1, size: 20};
                const {data: res} = await this.$http.post("mat/list", param);
                if (res.success) {
                    data = res.data.records.map(product => {
                        return {matId: product.matId, matName: product.matName, matSku: product.matSku};
                    })
                }
                callback(data);
            },
            itemSelect(item) {
                this.tempForm.matId = item.matId;
                this.tempForm.matSku = item.matSku;
                this.tempForm.matName = item.matName;
                this.tempForm.matUnit = item.matUnit;
            },
            addRankItem() {
                if (Number(this.tempForm.quantity) < 1 || Number(this.tempForm.price) < 0) {
                    this.$message.warning("价格或数量有误");
                    return;
                }
                this.$refs.tempFormRef.validate(valid => {
                    if (valid) {
                        // 做验证判断
                        const list = this.saleForm.list;
                        for (let i=0; i<list.length; i++) {
                            if (list[i].matId === this.tempForm.matId) {
                                this.$alert("已存在相同的产品");
                                return;
                            }
                        }
                        const item = {
                            matId: this.tempForm.matId,
                            matName: this.tempForm.matName,
                            matSku: this.tempForm.matSku,
                            quantity: this.tempForm.quantity,
                            price: this.tempForm.price,
                            matType: this.tempForm.matType,
                        };
                        this.saleForm.list.push(item);
                        this.$refs.tempFormRef.resetFields();
                    }else {
                        this.$message.warning("失败");
                    }
                })
            },
            submitForm() {
                let list = [];
                for (let i = 0; i < this.saleForm.list.length; i++) {
                    const item = this.saleForm.list[i];
                    list.push({matId: item.matId, quantity: item.quantity});
                }
                if (list.length < 1) {
                    this.$message.error("错误：项目为零");
                }
                this.$refs.saleFormRef.validate(async valid => {
                    if (valid) {
                        const {data: res} = await this.$http.post("sale/add", this.saleForm);
                        if (res.success) {
                            this.$message.success("创建成功");
                            this.$refs.saleFormRef.resetFields();
                            this.saleForm.list = [];
                        }else {
                            this.$message.error(res.message);
                        }
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>