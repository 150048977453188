<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item><a href="javascript:history.back(-1)">流程列表</a></el-breadcrumb-item>
            <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-descriptions title="流程信息" border>
                <!--<template slot="extra">
                    <el-button type="primary" size="small" @click="modifyItemQuantity()">修改</el-button>
                </template>-->
                <el-descriptions-item label="名称">{{flow.orderNo}}</el-descriptions-item>
                <el-descriptions-item label="备注" :span="2">{{flow.remark}}</el-descriptions-item>
            </el-descriptions>
            <div style="margin-top: 10px; margin-bottom: 5px">产品信息</div>
            <el-table :data="flowItems" width="100%">
                <el-table-column prop="matSku" label="代码"/>
                <el-table-column prop="matName" label="名称"/>
                <el-table-column prop="matUnit" label="单位"/>
                <el-table-column prop="quantity" label="数量">
                    <!--<template slot-scope="scope">
                        <el-input type="number" v-model="scope.row.modifyQuantity" :placeholder="scope.row.quantity" size="small" />
                    </template>-->
                </el-table-column>
            </el-table>
            <div style="margin-top: 10px; margin-bottom: 5px">拆分阶</div>
            <el-table :data="detailInfo.current" width="100%">
                <el-table-column prop="matSku" label="代码"/>
                <el-table-column prop="matName" label="名称"/>
                <el-table-column prop="orderQuantity" label="数量"/>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" @click="showProductItem(scope.row)" size="small">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>

        <!-- 弹出显示 -->
        <el-drawer
                size="45%"
                :title="detailDrawerTitle"
                :visible.sync="detailDrawer"
                direction="rtl">
            <el-table :data="rankList" width="100%" :span-method="objectSpanMethod">
                <el-table-column prop="mainName" label="母件名称"/>
                <el-table-column prop="typeName" label="类型"/>
                <el-table-column prop="matSku" label="代码"/>
                <el-table-column prop="matName" label="名称"/>
                <el-table-column prop="ratio1" label="B03"/>
                <el-table-column prop="ratio2" label="B04"/>
                <el-table-column prop="quantity" label="数量"/>
            </el-table>
        </el-drawer>
    </div>
</template>

<script>
    export default {
        name: "FlowDetail",
        data() {
            return {
                flowId: null,
                flow: {},
                flowItems:[],
                detailInfo: {
                    current: [],
                    all: [],
                },
                rankList: [],
                detailDrawer: false,
                detailDrawerTitle: '',
            }
        },
        created() {
            this.flowId = this.$route.query.id;
            this.getData();
        },
        methods: {
            async getData() {
                const {data:res} = await this.$http.post("flow/detail", {flowId: this.flowId});
                if (res.success) {
                    this.flow = res.data.flow;
                    this.flowItems = res.data.items.map(item =>{
                        item.modifyQuantity = item.quantity;
                        return item;
                    });
                    this.detailInfo.all = res.data.details;
                    this.detailInfo.current = this.detailInfo.all;
                }
            },
            showProductItem(row) {
                this.detailDrawerTitle = row.matName;
                this.currentMatId = row.matId;
                this.getRankList(row.orderQuantity);
                this.detailDrawer = true;
            },
            async getRankList(qty) {
                const param = {matId: this.currentMatId, qty: qty};
                const {data: res} = await this.$http.post("product/relative", param);
                if (res.success) {
                    this.rankList = [];
                    const list = res.data.matIds;
                    for (let i = 0; i < list.length; i++) {
                        const rank = res.data.matData[list[i]];
                        const quantity = this.getQuantityFromDetailInfoCurrent(rank.main.matId);
                        rank.main.mainName = rank.main.matName;
                        rank.main.mainSpan = rank.mzList.length + rank.zList.length + 1;
                        rank.main.rowSpan = 1;
                        this.rankList.push(rank.main);
                        for (let j = 0; j < rank.mzList.length; j++) {
                            let item = rank.mzList[j];
                            if (j === 0) {
                                item.rowSpan = rank.mzList.length;
                            }
                            this.rankList.push(item);
                        }
                        for (let j = 0; j < rank.zList.length; j++) {
                            let item = rank.zList[j];
                            if (j === 0) {
                                item.rowSpan = rank.zList.length;
                            }
                            this.rankList.push(item);
                        }
                    }
                }
            },
            getQuantityFromDetailInfoCurrent(matId) {
                for (let i = 0; i < this.detailInfo.current.length; i++) {
                    const info = this.detailInfo.current[i];
                    if (matId === info.matId) {
                        return info.orderQuantity;
                    }
                }
            },
            objectSpanMethod({ row, column, rowIndex, columnIndex }) {
                if (columnIndex === 0) {
                    if (row.mainSpan > 0){
                        return {
                            rowspan: row.mainSpan,
                            colspan: 1
                        };
                    } else {
                        return  {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                }
                if (columnIndex === 1) {
                    if (row.rowSpan > 0){
                        return {
                            rowspan: row.rowSpan,
                            colspan: 1
                        };
                    } else {
                        return  {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>