<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>拆分订单</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-form ref="saleFormRef" :model="saleForm" label-position="left" :rules="saleFormRules" inline size="medium">
                <el-row>
                    <el-col :span="10" required>
                        <el-form-item label="预计交期" prop="deliverDate">
                            <el-date-picker
                                    value-format="yyyy-MM-dd"
                                    v-model="saleForm.deliverDate"
                                    type="date"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10">
                        <el-form-item label="备注">
                            <el-input v-model="saleForm.remark"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-button type="primary" @click="submitForm" size="medium">创建拆分订单</el-button>
                    </el-col>
                </el-row>
            </el-form>

            <el-table :data="saleForm.list" width="100%" :key="timeStamp">
                <el-table-column label="类型">
                    <template slot-scope="scope">
                        <span v-if="scope.row.matType === 0">子件</span>
                        <span v-if="scope.row.matType === 1">母件</span>
                    </template>
                </el-table-column>
                <el-table-column prop="matSku" label="代码"/>
                <el-table-column prop="matName" label="名称"/>
                <el-table-column prop="originQuantity" label="总数量"/>
                <el-table-column label="母单数量">
                    <template slot-scope="scope">
                        <el-input type="number" v-model="scope.row.quantity" disabled/>
                    </template>
                </el-table-column>
                <el-table-column label="拆单数量">
                    <template slot-scope="scope">
                        <el-input type="number" v-model="scope.row.splitQuantity" :aria-valuemax="scope.row.quantity" @change="changeItemQuantity(scope.row)"/>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
    export default {
        name: "FlowDesign",
        data() {
            return {
                timeStamp: 0,
                saleForm: {
                    motherSaleId: '',
                    remark: '',
                    deliverDate: '',
                    list: [],
                },
                saleFormRules: {
                    deliverDate: [
                        {required: true, message: "请选择预计交期", trigger: 'blur'}
                    ]
                }
            }
        },
        created() {
            // 获取信息
            if (this.$route.query !== undefined && typeof this.$route.query.sale === 'object') {
                const sale = this.$route.query.sale;
                const saleItems = this.$route.query.saleItems;
                this.saleForm.deliverDate = sale.deliverDate;
                this.saleForm.motherSaleId = sale.saleId;

                this.saleForm.list = [];
                for (let i = 0; i < saleItems.length; i++) {
                    const item = {
                        matId: saleItems[i].matId,
                        matName: saleItems[i].matName,
                        matSku: saleItems[i].matSku,
                        quantity: saleItems[i].quantity,
                        originQuantity: saleItems[i].quantity,
                        splitQuantity: 0.0,
                        price: saleItems[i].price,
                        matType: saleItems[i].matType,
                    };
                    this.saleForm.list.push(item);
                }
            }else {
                this.$message.error("数据错误");
                this.$router.back();
            }
        },
        methods: {
            changeItemQuantity(item) {
                let motherQuantity = Number((item.originQuantity - item.splitQuantity).toFixed(2));
                if (motherQuantity < 0) {
                    item.quantity = item.originQuantity;
                    item.splitQuantity = 0.0;
                    this.$message.warning("输入的数量有误");
                }
                item.quantity = motherQuantity;
                this.timeStamp = new Date().getTime();
            },
            submitForm() {
                let list = [];
                for (let i = 0; i < this.saleForm.list.length; i++) {
                    const item = this.saleForm.list[i];
                    list.push({matId: item.matId, quantity: item.splitQuantity});
                }
                if (list.length < 1) {
                    this.$message.error("错误：项目为零");
                }
                this.$refs.saleFormRef.validate(async valid => {
                    if (valid) {
                        const param = {motherSaleId: this.saleForm.motherSaleId, deliverDate: this.saleForm.deliverDate, remark: this.saleForm.remark, list: list};
                        const {data: res} = await this.$http.post("sale/split", param);
                        if (res.success) {
                            this.$message.success("创建成功");
                            this.$refs.saleFormRef.resetFields();
                            this.saleForm.list = [];
                        }else {
                            this.$message.error(res.message);
                        }
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>