<template>
    <div>
        <div style="display: flex;justify-content: space-between;align-items: center">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>阶池数据</el-breadcrumb-item>
            </el-breadcrumb>
            <el-button size="mini" type="primary" @click="getData">更新数据</el-button>
        </div>

        <el-card>
            <el-tabs v-model="activeName" type="card">
                <el-tab-pane label="流程BOM" name="flow">
                    <el-card>
                        <el-table v-loading="loading" :data="flowData" width="100%" max-height="550" border :row-class-name="tableRowClassName">
                            <el-table-column fixed prop="itemTypeName" label="类型"/>
                            <el-table-column fixed prop="matSku" label="编码"/>
                            <el-table-column fixed prop="matName" label="名称"/>
                            <el-table-column prop="regionName" label="区间"/>
                            <template v-for="(col, index) in flowProps">
                                <el-table-column :label="col.propName" align="center">
                                    <template scope="scope">
                                        <el-button v-if="scope.row[col.propId+'-T'] === 'INPUT'" type="danger"  @click="showFlowInput(scope.row.itemId, col.propId)">{{scope.row[col.propId]}}</el-button>
                                        <span v-if="scope.row[col.propId+'-T'] !== 'INPUT'">{{scope.row[col.propId]}}</span>
                                    </template>
                                </el-table-column>
                            </template>
                        </el-table>
                    </el-card>
                </el-tab-pane>
                <el-tab-pane label="阶池组" name="rank">
                    <el-card>
                        <el-table v-loading="loading" :data="rankData" width="100%" max-height="550" border :row-class-name="tableRowClassName">
                            <el-table-column fixed prop="itemTypeName" label="类型"/>
                            <el-table-column fixed prop="matSku" label="编码"/>
                            <el-table-column fixed prop="matName" label="名称"/>
                            <el-table-column prop="regionName" label="区间"/>
                            <template v-for="(col, index) in rankProps">
                                <el-table-column :label="col.propName" align="center">
                                    <template scope="scope">
                                        <el-button v-if="scope.row[col.propId+'-T'] === 'INPUT'" type="danger"  @click="showFlowInput(scope.row.itemId, col.propId)">{{scope.row[col.propId]}}</el-button>
                                        <span v-if="scope.row[col.propId+'-T'] !== 'INPUT'">{{scope.row[col.propId]}}</span>
                                    </template>
                                </el-table-column>
                            </template>
                        </el-table>
                    </el-card>
                </el-tab-pane>
                <el-tab-pane label="废退期" name="residue">
                    <el-card>
                        <el-table v-loading="loading" :data="residueData" width="100%" max-height="550" border :row-class-name="tableRowClassName">
                            <el-table-column fixed prop="itemTypeName" label="类型"/>
                            <el-table-column fixed prop="matSku" label="编码"/>
                            <el-table-column fixed prop="matName" label="名称"/>
                            <el-table-column prop="regionName" label="区间"/>
                            <template v-for="(col, index) in residueProps">
                                <el-table-column :prop="col.propId" :label="col.propName" align="center"/>
                            </template>
                        </el-table>
                    </el-card>
                </el-tab-pane>
            </el-tabs>
        </el-card>
        <el-dialog title="输入数字"
                   :visible.sync="dialog.visible"
                   width="30%">
            <el-checkbox v-model="dialog.updateData">更新数据</el-checkbox>
            <el-input type="number" ref="valueInput" v-model="dialog.inputValue"  @keyup.enter.native="submitInputValue"/>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialog.visible = false">取 消</el-button>
                <el-button type="primary" @click="submitInputValue">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "PoolData",
        data() {
            return {
                dialog: {
                    visible: false,
                    inputValue: '',
                    id: "",
                    updateData: true,
                },
                loading: true,
                matId: 0,
                activeName: 'flow',
                flowData: [],
                rankData: [],
                residueData: [],
                flowProps: [],
                rankProps: [],
                residueProps: [],
            }
        },
        created() {
            this.matId = this.$route.query.id;
            this.getData();
        },
        methods: {
            showFlowInput(itemId, propId) {
                this.dialog.inputValue = '';
                this.dialog.id = itemId + "-" + propId;
                this.dialog.visible = true;
                this.$nextTick(() => {
                    this.$refs.valueInput.focus();
                });

            },
            async submitInputValue() {
                let param = [];
                param.push({poolId: this.dialog.id, value: this.dialog.inputValue, source: 'test'});
                const {data: res} = await this.$http.post("/pool/input", param);
                if (res.success) {
                    this.dialog.visible = false;
                    if (this.dialog.updateData) {
                        this.getData();
                    }
                }
            },
            tableRowClassName({row, rowIndex}) {
                if (row.itemType === 'M') {
                    return 'warning-row';
                } else if (row.itemType === 'MM') {
                    return 'success-row';
                }
                return '';
            },
            async getData() {
                if (this.matId == null) {
                    return ;
                }
                this.loading = true;
                const {data: res} = await this.$http.post("/pool/data", {matId: this.matId, relative: true});
                if (res.success) {
                    const result = res.data;
                    const ids = result.ids;
                    const matsData = result.data;
                    this.flowProps = result.flowProps;
                    this.rankProps = result.rankProps;
                    this.residueProps = result.residueProps;
                    this.flowData = [];
                    this.rankData = [];
                    this.residueData = [];
                    for (let i = 0; i < ids.length; i++) {
                        const matData = matsData[ids[i]];
                        // 流程数据
                        for (let j = 0; j < matData.flow.length; j++) {
                            this.flowData.push(matData.flow[j]);
                        }
                        // 阶池数据
                        for (let j = 0; j < matData.rank.gr.length; j++) {
                            this.rankData.push(matData.rank.gr[j]);
                        }
                        for (let j = 0; j < matData.rank.yl.length; j++) {
                            this.rankData.push(matData.rank.yl[j]);
                        }
                        // 废退数据
                        for (let j = 0; j < matData.residue.gr.length; j++) {
                            this.residueData.push(matData.residue.gr[j]);
                        }
                        for (let j = 0; j < matData.residue.yl.length; j++) {
                            this.residueData.push(matData.residue.yl[j]);
                        }
                    }
                }
                this.loading = false;
            }
        }
    }
</script>

<style>
    .el-table .warning-row {
        background: oldlace;
    }

    .el-table .success-row {
        background: #f0f9eb;
    }
</style>