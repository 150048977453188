<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>订单列表</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-row class="search-card">
                <el-col :span="3">
                    <el-select v-model="searchStatus" placeholder="状态">
                        <el-option label="创建" value="0"/>
                        <el-option label="计划" value="1"/>
                        <el-option label="结束" value="2"/>
                        <el-option label="取消" value="-1"/>
                    </el-select>
                </el-col>
                <el-col :span="3">
                    <el-autocomplete
                            popper-class="my-autocomplete"
                            v-model="searchMatName"
                            :fetch-suggestions="productSearch"
                            placeholder="产品"
                            :trigger-on-focus="false"
                            @select="itemSelect">
                        <template slot-scope="{ item }">
                            <div class="name">{{ item.matName }}</div>
                            <span class="addr">{{ item.matSku }}</span>
                        </template>
                    </el-autocomplete>
                </el-col>
                <el-col :span="4">
                    <!-- 搜索区域 -->
                    <el-input placeholder="搜索" v-model="searchValue" @keyup.enter.native="search">
                        <el-button slot="append" icon="el-icon-search" @click="search"/>
                    </el-input>
                </el-col>
            </el-row>
            <el-row>
                <el-table highlight-current-row
                          :data="saleList"
                          size="mini"
                          max-height="550"
                          border>
                    <el-table-column width="150px" prop="saleId" label="ID"/>
                    <el-table-column min-width="100px" prop="customerName" label="客户"/>
                    <el-table-column min-width="150px" prop="orderNo" label="单号"/>
                    <el-table-column min-width="100px" prop="deliverDate" label="预计交期"/>
                    <el-table-column min-width="100px" prop="remark" label="描述"/>
                    <el-table-column prop="status" label="状态">
                        <template scope="scope">
                            <span v-if="scope.row.status === 0">创建</span>
                            <span v-if="scope.row.status === 1">计划</span>
                            <span v-if="scope.row.status === 2">结束</span>
                            <span v-if="scope.row.status === -1">取消</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="200px">
                        <template slot-scope="scope">
                            <el-button type="text" @click="showSaleDetail(scope.row)" size="small">查看</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="page.currentPage"
                        :page-sizes="[10,20, 50, 100]"
                        :page-size="page.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="page.total">
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>

<script>
    export default {
        name: "FlowList",
        data() {
            return {
                page: {
                    pageSize: 10,
                    currentPage: 1,
                    total: 0,
                },
                searchValue: "",
                searchStatus: "",
                searchMatId: null,
                searchMatName: "",
                searchMatSku: "",
                saleList: [],
            }
        },
        created() {
            this.getData();
        },
        methods: {
            search() {
                this.page.currentPage = 1;
                this.getData();
            },
            handleSizeChange(val) {
                this.page.pageSize = val;
                this.getData();
            },
            handleCurrentChange(val) {
                this.page.currentPage = val;
                this.getData();
            },
            async showSaleDetail(item) {
                await this.$router.push({path: '/sale_detail', query: {id: item.saleId}});
            },
            async getData() {
                if (this.searchMatName === "") {
                    this.searchMatId = null;
                    this.searchMatSku = "";
                }
                const param = {current: this.page.currentPage, size: this.page.pageSize,
                    search: this.searchValue, status: this.searchStatus, matId: this.searchMatId};
                const {data: res} = await this.$http.post("sale/list", param);
                if (res.success) {
                    this.saleList = res.data.records;
                    this.page.total = res.data.total;
                }
            },
            async productSearch(str, callback) {
                let data = [];
                // 从后台获取数据
                const param = {search: str, current: 1, size: 20};
                const {data: res} = await this.$http.post("mat/list", param);
                if (res.success) {
                    data = res.data.records.map(product => {
                        return {matId: product.matId, matName: product.matName, matSku: product.matSku};
                    })
                }
                callback(data);
            },
            itemSelect(item) {
                this.searchMatId = item.matId;
                this.searchMatSku = item.matSku;
                this.searchMatName = item.matName;
            },
        },
        beforeRouteEnter(to,from,next) {
            // 从详情页返回不刷新
            if (from.path === '/sale_detail') {
                to.meta.keepAlive = true;
            }else {
                to.meta.keepAlive = false;
            }
            next();
        }
    }
</script>

<style scoped>

</style>