<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>产品修改</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-form ref="productFormRef" :model="productForm" label-position="left">
                <el-row>
                    <el-col :span="10">
                        <el-form-item label="母件" required>
                            <el-autocomplete
                                    style="width: 80%"
                                    popper-class="my-autocomplete"
                                    v-model="productForm.matName"
                                    :fetch-suggestions="productSearch"
                                    placeholder="请输入内容"
                                    :trigger-on-focus="false"
                                    @select="itemSelect">
                                <template slot-scope="{ item }">
                                    <div class="name">{{ item.matName }}</div>
                                    <span class="addr">{{ item.matSku }}</span>
                                </template>
                            </el-autocomplete>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10">
                        <el-form-item label="备注">
                            <el-input style="width: 80%" v-model="productForm.productDesc"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item>
                            <el-button type="primary" @click="submitForm" size="medium">
                                提交
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-table :data="rankList" width="100%" border :span-method="objectSpanMethod" :key="timeStamp">
                <el-table-column prop="mainName" label="母件名称"/>
                <el-table-column width="150px" prop="typeName" label="类型"/>
                <el-table-column label="代码">
                    <template slot-scope="scope">
                        <span>{{scope.row.matSku}}</span>
                        <span v-if="scope.row.bug" style="color: red">（未创建）</span>
                    </template>
                </el-table-column>
                <el-table-column prop="matName" label="名称"/>
                <el-table-column width="150px" label="B03">
                    <template slot-scope="scope">
                        <el-input v-if="scope.row.typeName !== '母件'" type="number" step="2" v-model="scope.row.ratio1" @change="changeRatio(scope.row)"/>
                    </template>
                </el-table-column>
                <el-table-column width="150px" label="B04">
                    <template slot-scope="scope">
                        <el-input v-if="scope.row.typeName !== '母件'" type="number" step="2" v-model="scope.row.ratio2" @change="changeRatio(scope.row)"/>
                    </template>
                </el-table-column>
                <el-table-column prop="record" label="操作记录">
                    <template slot-scope="scope">
                        <div v-for="record in scope.row.records" :key="record.index">{{record}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <template v-if="scope.row.typeName === '母件'">
                            <el-button type="text" @click="replaceItem(scope.row)">替换</el-button>
                            <el-button type="text" @click="addOther(scope.row, 1)">母子件</el-button>
                            <el-button type="text" @click="addOther(scope.row, 0)">子件</el-button>
                        </template>
                        <template v-else>
                            <el-button type="text" @click="deleteItem(scope.row)">删除</el-button>
                        </template>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <!-- 物料选择的对话框 -->
        <el-dialog
                :title="dialog.title"
                :visible.sync="dialog.visible"
                width="30%"
                :before-close="dialogClose">
            <el-form label-width="80px" ref="choiceFormRef" :rules="dialog.choiceFormRules" :model="dialog.choiceForm">
                <el-form-item label="物料" prop="matName">
                    <el-autocomplete
                            style="width: 80%"
                            popper-class="my-autocomplete"
                            v-model="dialog.choiceForm.matName"
                            :fetch-suggestions="matSearch"
                            placeholder="请输入内容"
                            :trigger-on-focus="false"
                            @select="choiceSelect">
                        <template slot-scope="{ item }">
                            <div class="name">{{ item.matName }}</div>
                            <span class="addr">{{ item.matSku }}</span>
                        </template>
                    </el-autocomplete>
                </el-form-item>
                <el-form-item label="B03" prop="ratio1" v-if="this.dialog.actionType === 'ADD'">
                    <el-input v-model="dialog.choiceForm.ratio1"/>
                </el-form-item>
                <el-form-item label="B04" prop="ratio2" v-if="this.dialog.actionType === 'ADD'">
                    <el-input v-model="dialog.choiceForm.ratio2"/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitChoiceForm('ruleForm')">确定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "ProductEdit",
        data() {
            return {
                timeStamp: 0,
                rawData: {},
                complete: false,
                productForm: {
                    matId: "",
                    matName: "",
                    matSku: "",
                    productDesc: "",
                },
                rankList: [],
                operationRecords: [],
                dialog: {
                    actionType: "",
                    mainMatId: "",
                    matType: "",
                    title: "",
                    visible: false,
                    choiceForm: {
                        matId: "",
                        matName: "",
                        matSku: "",
                        ratio1: 1.0,
                        ratio2: 1.0,
                        typeName: "",
                    },
                    choiceFormRules: {
                        matName: [
                            {required : true, message: "请输入物料名称", trigger : 'blur'},
                        ],
                        ratio1: [
                            {required : true, message: "请输入B03", trigger : 'blur'},
                        ],
                        ratio2: [
                            {required : true, message: "请输入B04", trigger : 'blur'},
                        ],
                    }
                },

            }
        },
        methods: {
            async getData() {
                const param = {matId: this.productForm.matId};
                const {data: res} = await this.$http.post("product/relative", param);
                if (res.success) {
                    const list = res.data.matIds;
                    for (let i = 0; i < list.length; i++) {
                        const rank = res.data.matData[list[i]];
                        rank.mzList.map(item => {
                            item.oldRatio1 = item.ratio1;
                            item.oldRatio2 = item.ratio2;
                        });
                        rank.zList.map(item => {
                            item.oldRatio1 = item.ratio1;
                            item.oldRatio2 = item.ratio2;
                        });
                    }
                    this.rawData = res.data;
                    this.operationRecords = [];
                    this.dealRawData();
                }
            },
            dealRawData() {
                this.rankList = [];
                const list = this.rawData.matIds;
                for (let i = 0; i < list.length; i++) {
                    const rank = this.rawData.matData[list[i]];
                    rank.main.mainName = rank.main.matName;
                    rank.main.records = this.getRecords(rank.main.matId, null);
                    rank.main.mainSpan = rank.mzList.length + rank.zList.length + 1;
                    rank.main.rowSpan = 1;
                    this.rankList.push(rank.main);
                    for (let j = 0; j < rank.mzList.length; j++) {
                        let item = rank.mzList[j];
                        item.records = this.getRecords(rank.main.matId, item.matId);
                        item.mainMatId = rank.main.matId;
                        if (j === 0) {
                            item.rowSpan = rank.mzList.length;
                        }
                        this.rankList.push(item);
                    }
                    for (let j = 0; j < rank.zList.length; j++) {
                        let item = rank.zList[j];
                        item.records = this.getRecords(rank.main.matId, item.matId);
                        item.mainMatId = rank.main.matId;
                        if (j === 0) {
                            item.rowSpan = rank.zList.length;
                        }
                        this.rankList.push(item);
                    }
                }
            },
            getRecords(mainMatId, matId) {
                let records = [];
                for (let i = 0; i < this.operationRecords.length; i++) {
                    const record = this.operationRecords[i];
                    if (record.mainMatId === mainMatId) {
                        if (record.type === 'REPLACE' && matId === null) {
                            records.push(record.desc);
                        }
                        if (record.matId === matId) {
                            records.push(record.desc);
                        }
                    }
                }
                return records;
            },
            async productSearch(str, callback) {
                let data = [];
                // 从后台获取数据
                const param = {search: str, current: 1, size: 20};
                const {data: res} = await this.$http.post("product/list", param);
                if (res.success) {
                    data = res.data.records.map(product => {
                        return {matId: product.matId, matName: product.matName, matSku: product.matSku};
                    })
                }
                callback(data);
            },
            itemSelect(item) {
                this.productForm.matId = item.matId;
                this.productForm.matSku = item.matSku;
                this.productForm.matName = item.matName;
                this.rankList = [];
                this.getData();
            },
            objectSpanMethod({ row, column, rowIndex, columnIndex }) {
                if (columnIndex === 0) {
                    if (row.mainSpan > 0){
                        return {
                            rowspan: row.mainSpan,
                            colspan: 1
                        };
                    } else {
                        return  {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                }
                if (columnIndex === 1) {
                    if (row.rowSpan > 0){
                        return {
                            rowspan: row.rowSpan,
                            colspan: 1
                        };
                    } else {
                        return  {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                }
            },
            replaceItem(mItem) {
                this.dialog.actionType = "REPLACE";
                this.dialog.matType = 1;
                this.dialog.mainMatId = mItem.matId;
                this.dialog.title = mItem.matName + "选择替换";
                this.dialog.typeName = "母件";
                this.dialog.visible = true;
            },
            addOther(mItem, matType) {
                this.dialog.actionType = "ADD";
                this.dialog.matType = matType;
                this.dialog.mainMatId = mItem.matId;
                if (matType === 0) {
                    this.dialog.title = mItem.matName + "增加子件";
                    this.dialog.typeName = "子件";
                }else if (matType === 1){
                    this.dialog.title = mItem.matName + "增加母子件";
                    this.dialog.typeName = "母子件";
                }
                this.dialog.visible = true;
            },
            deleteItem(item) {
                item.records = ["已删除"];
                this.operationRecords.push({mainMatId: item.mainMatId, matId: item.matId, type: "DELETE", desc: "已删除"});
                // 更新表格组件的key，促使刷新
                this.timeStamp = new Date().getTime();
            },
            changeRatio(item) {
                let exitOtherActionFlag = false;
                for (let i = 0; i < this.operationRecords.length; i++) {
                    const record = this.operationRecords[i];
                    if (record.mainMatId === item.mainMatId && record.matId === item.matId && record.type !== 'UPDATE') {
                        // 已有操作项，删除或者新增，操作等级高于修改阶比，无需重复记录
                        exitOtherActionFlag = true
                    }
                }
                if (!exitOtherActionFlag) {
                    // 判断是不是修改
                    if (Number(item.ratio1) === Number(item.oldRatio1) && Number(item.ratio2) === Number(item.oldRatio2)) {
                        // 阶比改回原来阶比，取消操作记录
                        item.records = [];
                        for (let i = 0; i < this.operationRecords.length; i++) {
                            const record = this.operationRecords[i];
                            if (record.mainMatId === item.mainMatId && record.matId === item.matId && record.type !== 'UPDATE') {
                                this.operationRecords.splice(i,1);
                            }
                        }
                    }else {
                        if (item.records.length < 1) {
                            item.records = ["修改阶比"];
                            this.operationRecords.push({mainMatId: item.mainMatId, matId: item.matId, ratio1: item.ratio1, ratio2: item.ratio2, type: "UPDATE", desc: "修改阶比"});
                        }
                    }
                }
                // 更新表格组件的key，促使刷新
                this.timeStamp = new Date().getTime();
            },
            async matSearch(str, callback) {
                let data = [];
                // 从后台获取数据
                const param = {search: str, type: this.dialog.matType, current: 1, size: 20};
                const {data: res} = await this.$http.post("mat/list", param);
                if (res.success) {
                    data = res.data.records.map(mat => {
                        return {matId: mat.matId, matName: mat.matName, matSku: mat.matSku, matType: mat.matType};
                    });
                }
                callback(data);
            },
            choiceSelect(item) {
                if (item.matType!= null && item.matType !== this.dialog.matType) {
                    return;
                }
                this.dialog.choiceForm.matId = item.matId;
                this.dialog.choiceForm.matSku = item.matSku;
                this.dialog.choiceForm.matName = item.matName;
            },
            dialogClose() {
                this.dialog.choiceForm = {
                    matId: "",
                    matName: "",
                    matSku: "",
                    ratio1: 1.0,
                    ratio2: 1.0,
                };
                this.dialog.visible = false;
            },
            submitChoiceForm() {
                this.$refs.choiceFormRef.validate(valid => {
                    if (valid) {
                        // 判断选择的材料是不是在阶内已经存在
                        let rank = this.rawData.matData[this.dialog.mainMatId];
                        if (this.dialog.actionType === 'ADD') {
                            // 不等于母件
                            if (rank.main.matId === this.dialog.choiceForm.matId) {
                                this.$message.error("错误：选择的物料和母件相同");
                                return;
                            }
                            for (let i = 0; i < rank.mzList.length; i++) {
                                let item = rank.mzList[i];
                                if (item.matId === this.dialog.choiceForm.matId) {
                                    this.$message.error("错误：选择的物料已存在");
                                    return;
                                }
                            }
                            for (let i = 0; i < rank.zList.length; i++) {
                                let item = rank.zList[i];
                                if (item.matId === this.dialog.choiceForm.matId) {
                                    this.$message.error("错误：选择的物料已存在");
                                    return;
                                }
                            }
                            let item = this.dialog.choiceForm;
                            item.records = ["新增项"];
                            if (this.dialog.matType === 0) {
                                rank.zList.push(item);
                            }else if (this.dialog.matType === 1) {
                                rank.mzList.push(item);
                            }
                            // 增加操作记录
                            this.operationRecords.push({mainMatId: this.dialog.mainMatId, matId: item.matId, matType: this.dialog.matType, type: this.dialog.actionType, desc: "新增项"});
                        }else if(this.dialog.actionType === 'REPLACE') {
                            rank.main.records = ["替换为：" + this.dialog.choiceForm.matSku];
                            // 增加操作记录
                            this.operationRecords.push({mainMatId: this.dialog.mainMatId, matId: this.dialog.choiceForm.matId, type: this.dialog.actionType, desc: "替换为：" + this.dialog.choiceForm.matSku});
                        }
                        this.dialogClose();
                        this.dealRawData();
                        // 更新表格组件的key，促使刷新
                        this.timeStamp = new Date().getTime();
                    }else {
                        this.$message.warning("核验未通过");
                    }
                });
            },
            submitForm() {
                if (this.operationRecords.length <1) {
                    this.$message.warning("无数据提交");
                    return;
                }
                let records = {};
                const matIds = this.rawData.matIds;
                for (let i = 0; i < matIds.length; i++) {
                    let arr = [];
                    for (let j = 0; j < this.operationRecords.length; j++) {
                        const record = this.operationRecords[j];
                        if (record.mainMatId === matIds[i]) {
                            if (record.type === 'ADD' || record.type === 'UPDATE') {
                                const rankListItem = this.getRankListItem(record.mainMatId, record.matId);
                                if (rankListItem == null) {
                                    this.$message.error("异常情况，请联系管理员。code:product_edit_0001");
                                    return;
                                }
                                record.ratio1 = rankListItem.ratio1;
                                record.ratio2 = rankListItem.ratio2;
                            }
                            arr.push(record);
                        }
                    }
                    if (arr.length > 0) {
                        records[matIds[i]] = arr;
                    }
                }
                const param = {matIds: matIds, records: records, oldMatId: this.productForm.matId, productDesc: this.productForm.productDesc};
                this.$refs.productFormRef.validate(async valid => {
                    if (valid) {
                        const {data: res} = await this.$http.post("product/edit", param);
                        if (res.success) {
                            this.$message.success("修改成功");
                            this.$refs.productFormRef.resetFields();
                            this.rankList = [];
                        }else {
                            this.$message({
                                message: res.message,
                                type: "error",
                                showClose: true,
                                duration: 0
                            })
                        }
                    }
                });
            },
            getRankListItem(mainMatId, matId) {
                for (let i = 0; i < this.rankList.length; i++) {
                    const item = this.rankList[i];
                    if (mainMatId === item.mainMatId && matId === item.matId) {
                        return item;
                    }
                }
                return null;
            },
        }
    }
</script>

<style scoped>

</style>