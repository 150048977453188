<template>
    <!-- 引入container布局 -->
    <el-container>
        <el-aside width="200px">
            <!-- unique-opened -->
            <el-menu background-color="#545c64" text-color="#fff" active-text-color="#ffd04b" :router="true" unique-opened >
                <el-submenu :index="item.id" v-for="item in menuList" :key="item.id">
                    <template slot="title">
                        <i class="el-icon-location"></i>
                        <span>{{item.title}}</span>
                    </template>
                    <el-menu-item :index="it.path" v-for="it in item.subs" :key="it.id">
                        {{it.title}}
                    </el-menu-item>
                </el-submenu>
            </el-menu>
        </el-aside>
        <el-container>
            <el-header>
                <span>数量道管理系统</span>
                <el-button type="info" @click="loginOut()">安全退出</el-button>
            </el-header>
            <el-main>
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>

</template>
<script>
    export default {
        data() {
            return {
                menuList :[],
            }
        },
        // 页面加载时执行
        created() {
            // 查询menu
            this.getMenuList();
        },
        methods: {
            loginOut() {
                // 清除session
                window.sessionStorage.clear();
                this.$router.push("/login");
            },
            // 获取导航菜单方法
            async getMenuList() {
                this.menuList = await this.sessionStorageData.getMenu();
            },
        }
    }
</script>
<style scoped>
    .el-container {
        height: 100%;
    }
    .el-header {
        background-color: #B3C0D1;
        color: #333;
        line-height: 45px;
        display: flex;
        justify-content: space-between;
        padding-left: 0%;
        font-size: 20px;
        align-items: center;
        padding-left: 15px;
    }

    .el-aside {
        background-color: rgb(84, 92, 100);
        color: #333;
        text-align: center;
        line-height: 200px;
        border-right: none;
    }
    .el-menu {
        border-right: none;
    }

    .el-main {
        background-color: #E9EEF3;
    }

    body > .el-container {
        margin-bottom: 40px;
    }
    img {
        width: 45px;
        height: 45px;
    }
</style>