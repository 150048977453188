<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>阶池列配置</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-row>
                <el-table highlight-current-row
                          :data="propList.filter(data=> !search || data.propName.toLowerCase().includes(search.toLowerCase()) || data.propCode.toLowerCase().includes(search.toLowerCase()))"
                          size="mini"
                          max-height="550"
                          border>
                    <el-table-column prop="propId" label="列号"/>
                    <el-table-column prop="propCode" label="代码"/>
                    <el-table-column prop="propName" label="名称"/>
                    <el-table-column label="操作" width="200px">
                        <template slot="header" slot-scope="scope">
                            <el-input
                                    v-model="search"
                                    size="mini"
                                    placeholder="搜索"/>
                        </template>
                        <template slot-scope="scope">
                            <el-button type="text" @click="showPropItem(scope.row)" size="small">查看</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-row>
        </el-card>
        <el-dialog :title="dialog.title" :visible.sync="dialog.visible" fullscreen>
            <el-button type="primary" size="medium" @click="showInner">增加算法</el-button>
            <el-table :data="dialog.methods">
                <el-table-column property="itemType" label="项目类型"/>
                <el-table-column property="methodType" label="算法类型"/>
                <el-table-column property="method" label="算法"/>
                <el-table-column property="putType" label="输入/输出"/>
            </el-table>

            <el-dialog :title="inner.title" :visible.sync="inner.visible" append-to-body>
                <el-form ref="methodRef" label-width="80px" :model="method">
                    <el-form-item label="项目类型" prop="itemType">
                        <el-select v-model="method.itemType" placeholder="请选择">
                            <el-option
                                    v-for="item in itemTypes"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="计算类型" prop="methodType">
                        <el-select v-model="method.methodType" placeholder="请选择">
                            <el-option
                                    v-for="item in methodTypes"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="输入输出" prop="putType" >
                        <el-select v-model="method.putType" placeholder="请选择">
                            <el-option label="输入" value="1"/>
                            <el-option label="输出" value="2"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="提取位置">
                        <el-select v-model="current.location" placeholder="请选择">
                            <el-option label="自身" value=""/>
                            <el-option label="母件" value="M"/>
                            <el-option label="主件母子件" value="MMZ"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="列">
                        <el-select v-model="current.propId" placeholder="请选择">
                            <el-option
                                    v-for="item in propList"
                                    :label="item.propName"
                                    :value="item.propId">
                            </el-option>
                        </el-select>
                        <el-button @click.prevent="addProp" size="medium">增加</el-button>
                    </el-form-item>
                    <el-form-item label="运算符号">
                        <el-select v-model="current.symbol" placeholder="请选择">
                            <el-option value="+" label="加"/>
                            <el-option value="-" label="减"/>
                            <el-option value="*" label="乘"/>
                            <el-option value="/" label="除"/>
                        </el-select>
                        <el-button @click.prevent="addSymbol" size="medium">增加</el-button>
                    </el-form-item>
                    <el-form-item label="算法" prop="method">
                        <el-input v-model="method.method"/>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="createMethod">立即创建</el-button>
                        <el-button @click="inner.visible = false">取消</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "PropList",
        data() {
            return {
                propList: [],
                search: '',
                dialog: {
                    visible: false,
                    title: '',
                    methods: [],
                },
                inner: {
                    visible : false,
                    title: '增加算法'
                },
                itemTypes: [
                    {value: 'MM', label: '主件母件'},
                    {value: 'MMZ', label: '主件母子件'},
                    {value: 'MZZ', label: '主件外购件'},
                    {value: 'M', label: '母件'},
                    {value: 'MZ', label: '母子件'},
                    {value: 'Z', label: '子件'},
                ],
                methodTypes: [
                    {value: 'METHOD', label: 'METHOD'},
                    {value: 'DESIGN', label: 'DESIGN'},
                    {value: 'INPUT', label: 'INPUT'},
                    {value: 'STATISTIC', label: 'STATISTIC'},
                    {value: 'AMASS', label: 'AMASS'},
                ],
                method: {
                    propId: 0,
                    propName: '',
                    propCode: '',
                    itemType: '',
                    methodType: '',
                    method: '',
                    putType: '',
                },
                current: {
                    location: '',
                    propId: '',
                    symbol: '',
                }
            }
        },
        created() {
            this.getData();
        },
        methods: {
            async getData() {
                const {data: res} = await this.$http.post("/prop/all", null);
                if (res.success) {
                    this.propList = res.data;
                }
            },
            async showPropItem(prop) {
                this.method.propId = prop.propId;
                this.method.propName = prop.propName;
                const {data: res} = await this.$http.post("/prop/method/list", {propId: prop.propId});
                if (res.success) {
                    this.dialog.methods = res.data;
                    this.dialog.title = prop.propName + "算法";
                    this.dialog.visible = true;
                }
            },
            showInner() {
                this.inner.visible = true;
                if (this.$refs.methodRef !== undefined) {
                    this.$refs.methodRef.resetFields();
                    this.current = {
                        location: '',
                        propId: '',
                        symbol: '',
                    }
                }
            },
            async createMethod() {
                const param = this.method;
                const {data: res} = await this.$http.post("/prop/method/add", param);
                if (res.success) {
                    this.inner.visible = false;
                    this.$message.success("添加成功");
                    this.showPropItem({propId: this.method.propId, propName: this.method.propName});
                    this.$refs.methodRef.resetFields();
                }else {
                    this.$message.error(res.message);
                }
            },
            addProp() {
                let str = '';
                if (this.current.location !== '') {
                    str = this.current.location + ".";
                }
                this.method.method = this.method.method + str + this.current.propId;
            },
            addSymbol() {
                this.method.method = this.method.method + ";" + this.current.symbol + ";";
            }
        }
    }
</script>

<style scoped>

</style>