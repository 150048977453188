<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item><a href="javascript:history.back(-1)">订单列表</a></el-breadcrumb-item>
            <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-descriptions title="订单信息" border :column="1">
                <template slot="extra">
                    <el-button type="primary" size="small" @click="modifySale">修改</el-button>
                </template>
                <el-descriptions-item label="单号">{{sale.orderNo}}</el-descriptions-item>
                <el-descriptions-item label="客户">{{sale.customerName}}</el-descriptions-item>
                <el-descriptions-item label="预计交期">
                    <el-date-picker
                            value-format="yyyy-MM-dd"
                            v-model="sale.deliverDate"
                            type="date"
                            placeholder="选择日期">
                    </el-date-picker>
                </el-descriptions-item>
                <el-descriptions-item label="总价">{{sale.totalPrice}}</el-descriptions-item>
                <el-descriptions-item label="备注" :span="2">
                    <el-input v-model="sale.remark" auto-complete="false"/>
                </el-descriptions-item>
            </el-descriptions>
            <div style="margin-top: 10px; margin-bottom: 5px; display: flex; justify-content: space-between;">
                <span>产品信息</span>
                <div>
                    <el-button type="primary" style="margin-right: 10px" size="small" @click="copySale">复制</el-button>
                    <el-button v-if="sale.motherSaleId === null" type="warning" style="margin-right: 10px" size="small" @click="splitSale">拆分</el-button>
                </div>
                <!--<el-button type="primary" style="margin-right: 10px" size="small" @click="dialog.visible = true">添加产品</el-button>-->
            </div>
            <el-table :data="saleItems" :key="timeStamp" width="100%">
                <el-table-column label="类型">
                    <template slot-scope="scope">
                        <span v-if="scope.row.matType === 0">子件</span>
                        <span v-if="scope.row.matType === 1">母件</span>
                    </template>
                </el-table-column>
                <el-table-column prop="matSku" label="代码"/>
                <el-table-column prop="matName" label="名称"/>
                <el-table-column v-if="sale.motherSaleId !== null" prop="motherItemQuantity" label="主单数量"/>
                <el-table-column label="数量">
                    <template slot-scope="scope">
                        <el-input type="number" v-model="scope.row.modifyQuantity" :placeholder="scope.row.quantity"
                                  size="small" @blur="isItemChange(scope.row)" />
                    </template>
                </el-table-column>
                <el-table-column label="价格">
                    <template slot-scope="scope">
                        <el-input type="number" v-model="scope.row.modifyPrice" :placeholder="scope.row.price" :disabled="true"
                                  size="small"  @blur="isItemChange(scope.row)"/>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="200px">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.change" type="text" @click="submitItemModify(scope.row)">确认</el-button>
                        <!-- 主单才允许增加同类产品，通过originQuantity判断，只有主单主产品才有这个数字 -->
                        <el-button v-if="scope.row.matType === 1 && scope.row.originQuantity != null && scope.row.motherSaleId === undefined " type="text" @click="onAddItem(scope.row)">新增替换件</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div v-if="splitCols.length > 0">
                <div style="margin-top: 10px; margin-bottom: 5px;">
                    <span>拆分订单信息</span>
                </div>
                <el-table :data="saleItems" :key="timeStamp" width="100%">
                    <el-table-column label="类型">
                        <template slot-scope="scope">
                            <span v-if="scope.row.matType === 0">子件</span>
                            <span v-if="scope.row.matType === 1">母件</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="matSku" label="代码"/>
                    <el-table-column prop="matName" label="名称"/>
                    <template v-for="(col, index) in splitCols">
                        <el-table-column :prop="col.prop" :label="col.label"/>
                    </template>
                </el-table>
            </div>
        </el-card>
        <el-dialog
                title="增加产品"
                :visible.sync="dialog.visible"
                width="30%"
                :before-close="dialogClose">
            <el-form label-width="80px" ref="choiceFormRef" :rules="dialog.choiceFormRules" :model="dialog.choiceForm">
                <el-form-item label="产品" prop="matName">
                    <el-autocomplete
                            popper-class="my-autocomplete"
                            v-model="dialog.choiceForm.matName"
                            :fetch-suggestions="productSearch"
                            placeholder="请输入内容"
                            :trigger-on-focus="true"
                            @select="choiceSelect">
                        <template slot-scope="{ item }">
                            <div class="name">{{ item.matName }}</div>
                            <span class="addr">{{ item.matSku }}</span>
                        </template>
                    </el-autocomplete>
                </el-form-item>
                <el-form-item label="数量" prop="quantity">
                    <el-input v-model="dialog.choiceForm.quantity"/>
                </el-form-item>
                <el-form-item label="价格" prop="price">
                    <el-input v-model="dialog.choiceForm.price"/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitChoiceForm()">确定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "SaleDetail",
        data() {
            return {
                saleId: null,
                sale: {
                    motherSaleId: 0
                },
                saleItems: [],
                sonItems: [],
                splitCols: [],
                timeStamp: 0,
                priceEdit: false,
                dialog: {
                    visible: false,
                    belongMat: '',
                    maxQuantity: 0,
                    choiceForm: {
                        belongItemId: '',
                        matId: '',
                        matName: "",
                        matSku: "",
                        quantity: 0.0,
                        price: 0.0,
                    },
                    choiceFormRules: {
                        matName: [
                            {required : true, message: "请选择产品", trigger : 'blur'},
                        ],
                        quantity: [
                            {required : true, message: "请输入数量", trigger : 'blur'},
                        ],
                        price: [
                            {required : true, message: "请输入价格", trigger : 'blur'},
                        ],
                    }
                },
            }
        },
        created() {
            this.saleId = this.$route.query.id;
            this.getData();
        },
        methods: {
            async splitSale() {
                // 跳转到拆分订单页面
                await this.$router.push({path: '/sale_split', query:{sale: this.sale, saleItems: this.saleItems} });
            },
            async copySale() {
                // 跳转到订单设计页面
                await this.$router.push({path: '/sale_design', query:{sale: this.sale, saleItems: this.saleItems} });
            },
            async getData() {
                const {data:res} = await this.$http.post("sale/detail", {saleId: this.saleId});
                if (res.success) {
                    const splitSales = res.data.splitSales;
                    this.sale = res.data.sale;
                    this.saleItems = res.data.items.map(item =>{
                        item.modifyQuantity = item.quantity;
                        item.modifyPrice = item.price;
                        item["saleId-" + this.sale.saleId] = item.quantity;
                        for (let i = 0; i < splitSales.length; i++) {
                            item["saleId-" + splitSales[i].saleId] = splitSales[i].splitItems[item.matId];
                        }
                        this.$set(item, "change", false);
                        return item;
                    });
                    if (splitSales.length > 0) {
                        let splitCols = [{prop: "saleId-"+this.sale.saleId, label: this.sale.orderNo}];
                        for (let i = 0; i < splitSales.length; i++) {
                            splitCols.push({prop: "saleId-"+splitSales[i].saleId, label: splitSales[i].orderNo });
                        }
                        this.splitCols = splitCols;
                    }
                    // 只有主单能修改价格
                    this.priceEdit = this.sale.motherSaleId != null;
                }
            },
            isItemChange(item) {
                item.change = Number(item.modifyPrice) !== Number(item.price) || Number(item.modifyQuantity) !== Number(item.quantity);
                this.timeStamp = new Date().getTime();
            },
            async modifySale() {
                const param = {saleId: this.sale.saleId, deliverDate: this.sale.deliverDate, remark: this.sale.remark};
                console.log(param)
                const {data: res} = await this.$http.post("sale/edit", param);
                if (res.success) {
                    this.$message.success("修改成功");
                } else {
                    this.$message.error(res.message);
                }
            },
            restore(item) {
                item.modifyQuantity = item.quantity;
                item.modifyPrice = item.price;
                item.change = false;
            },
            async submitItemModify(row) {
                if (Number(row.modifyQuantity) < 0 || Number(row.modifyPrice) < 0) {
                    this.restore(row);
                    this.$message.error("价格或者数量不能小于0");
                    return ;
                }
                // 判断修改的数量是不是超过主项目数量
                for (let i = 0; i < this.saleItems.length; i++) {
                    const item = this.saleItems[i];
                    if (row.belongItemId === item.itemId) {
                        if (Number(row.modifyQuantity) - row.quantity > item.quantity) {
                            this.restore(row);
                            this.$message.error("增加的数量大于主项目的数量，请确认");
                            return ;
                        }
                    }
                }
                // 向后台提交申请
                const param = {itemId: row.itemId, saleId: this.saleId, quantity: row.modifyQuantity, price: row.modifyPrice};
                const {data: res} = await this.$http.post("sale/item/edit", param);
                if (res.success) {
                    row.quantity = Number(row.modifyQuantity);
                    row.price = Number(row.modifyPrice);
                    row.change = false;
                    this.sale.totalPrice = res.data;
                    this.timeStamp = new Date().getTime();
                    this.$message.success("修改成功");
                    this.getData();
                }else {
                    this.restore(row);
                    this.$message.error(res.message);
                }
            },
            async productSearch(str, callback) {
                let data = [];
                // 从后台获取数据
                const param = {search: str, belong: this.dialog.belongMat, current: 1, size: 20};
                const {data: res} = await this.$http.post("mat/list", param);
                if (res.success) {
                    data = res.data.records.map(mat => {
                        return {matId: mat.matId, matName: mat.matName, matSku: mat.matSku};
                    })
                }
                callback(data);
            },
            choiceSelect(item) {
                this.dialog.choiceForm.matId = item.matId;
                this.dialog.choiceForm.matName = item.matName;
                this.dialog.choiceForm.matSku = item.matSku;
            },
            onAddItem(row) {
                this.dialog.visible = true;
                this.dialog.belongMat = row.matId;
                this.dialog.choiceForm.belongItemId = row.itemId;
                this.dialog.maxQuantity = row.quantity;
            },
            dialogClose() {
                this.dialog.choiceForm = {
                    matId: "",
                    matName: "",
                    matSku: "",
                };
                this.dialog.visible = false;
            },
            submitChoiceForm() {
                this.$refs.choiceFormRef.validate(async valid => {
                    if (valid) {
                        // 判断数量是否大于主项目数量
                        if (Number(this.dialog.choiceForm.quantity) > this.dialog.maxQuantity) {
                            this.$message.error("数量大于主项目数量： " + this.dialog.maxQuantity);
                            return ;
                        }
                        // 判断添加的产品是否重复
                        for (let i = 0; i < this.saleItems.length; i++) {
                            const item = this.saleItems[i];
                            if (item.matId === this.dialog.choiceForm.matId) {
                                this.$message.error("订单内已有该产品");
                                return ;
                            }
                        }
                        const param = {
                            saleId: this.saleId,
                            belongItemId: this.dialog.choiceForm.belongItemId,
                            matId: this.dialog.choiceForm.matId,
                            quantity: this.dialog.choiceForm.quantity,
                            price: this.dialog.choiceForm.price
                        };
                        const {data: res} = await this.$http.post("sale/item/add", param);
                        if (res.success) {
                            this.$message.success("添加成功");
                            this.dialogClose();
                            this.getData();
                        }
                    }
                });
            },
        }
    }
</script>

<style scoped>

</style>